import React from 'react';
import { Route, Switch, Redirect, BrowserRouter as Router } from 'react-router-dom';

import { PlayRedirect } from "./PlayRedirect";
import { GameplayScreen } from "./views/gameplay";

import { AuthenticationLayout } from './layout/AuthenticationLayout';

// Views
import { MenuScreen } from './views/MenuScreen';
import { SplashScreen } from './views/SplashScreen';

// Contexts
import { AuthContext } from './contexts/AuthContext';

import { LocalStorage, localStorageKeys } from './services/localStorage';
import { api } from './services/api';
import { CategoryMenuScreen } from './views/CategoryMenuScreen';
import { SignInScreen } from './views/auth/SignInScreen';
import { SignUpScreen } from './views/auth/SignUpScreen';

function App() {
  const [state, dispatch] = React.useReducer(
    (prevState, action) => {
      switch (action.type) {
        case 'RESTORE_TOKEN':
          return {
            ...prevState,
            userToken: action.token,
            isLoading: false,
          };
        case 'SIGN_IN':
          return {
            ...prevState,
            isLoading: false,
            userToken: action.token,
          };
        case 'SIGN_OUT':
          return {
            ...prevState,
            isLoading: false,
            userToken: null,
          };
        case 'REFRESH':
          return {
            ...prevState,
            isLoading: false
          };
      }
    },
    {
      isLoading: true,
      userToken: null,
    }
  );

  React.useEffect(() => {
    (async function () {
      const token = LocalStorage.retrieve(localStorageKeys.ACCESS_TOKEN);

      if (token) {
        await api.post(`/games/login/token`, null, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })
          .then(response => {
            const { user, token } = response.data;
            if (!isNaN(user.profile_id)) {
              return authContext.signIn({ token });
            }
            dispatch({ type: 'REFRESH' });
          })
          .catch(() => {
            // If token validation fails, just ignore it
            dispatch({ type: 'REFRESH' });
          });
      } else {
        dispatch({ type: 'REFRESH' });
      }
    })();
  }, []);

  const authContext = React.useMemo(
    () => ({
      signIn: async data => {
        LocalStorage.store(localStorageKeys.ACCESS_TOKEN, data.token);
        api.defaults.headers.common['Authorization'] = `Bearer ${data.token}`;

        dispatch({ type: 'SIGN_IN', token: data.token });
      },
      signOut: () => {
        localStorage.clear();
        delete api.defaults.headers.common['Authorization'];

        dispatch({ type: 'SIGN_OUT' });
      }
    }),
    []
  );

  if (state.isLoading) {
    return null;
  }

  return (
    <AuthContext.Provider value={authContext}>
      <Router>
        <Switch>
          <Route exact path="/jogar/:case_slug" component={PlayRedirect} />
          {state.userToken ? (
            <>
              <Route exact path="/" component={SplashScreen} />
              <Route exact path="/menu" component={MenuScreen} />
              <Route exact path="/categorias" component={CategoryMenuScreen} />
              <Route exact path="/categorias/:id/casos" component={MenuScreen} />
              <Route exact path="/jogar" component={GameplayScreen} />
            </>
          ) : (
            <>
              <Route path="/" render={props => <AuthenticationLayout {...props} />} />
            </>
          )}
          <Redirect to="/" />
        </Switch>
      </Router>
    </AuthContext.Provider>
  );
}

export default App;

import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

// Views
import { SignInScreen } from "../views/auth/SignInScreen";
import { SignUpScreen } from "../views/auth/SignUpScreen";

// Images
import Banner from "../assets/images/brand/banner.png";

export const AuthenticationLayout = (props) => {
  return (
    <div className="fullscreen gamecase-bg-default align-center">
      <div className="auth-panel-wrapper rounded">
        <div className="align-center mb-2">
          <img src={Banner} style={{width:'calc(8.912 * var(--sp))'}} />
        </div>
        
        <Switch>
          <Route exact path="/" component={SignInScreen} />
          <Route exact path="/cadastrar" component={SignUpScreen} />

          <Redirect to="/" />
        </Switch>
      </div>
    </div>
  );
}
import React from "react";

import { Typography, Container } from "../components/common";
import { CircleButton } from "../components/CircleButton";

import { BackIcon } from "../assets/icons";

import CreditsImage from "../assets/images/credits.png";

const gameCredits = require("../config/game.credits.json");

export const CreditsScreen = ({ onClose, ...props }) => {
  return (
    <Container
      containerClassName="gamecase-bg-default"
      className="text-full-white p-2b d-flex flex-column"
    >
      <CircleButton
        className="p-absolute"
        style={{ top: "calc(2 * var(--sp))", left: "calc(2 * var(--sp))" }}
        onClick={() => onClose()}
      >
        <BackIcon className="w-100 h-100" />
      </CircleButton>
      {/* <div className="gamecase-bg-default fullscreen text-white p-2 d-flex flex-column"> */}
      <Typography bold variant='header34'
        className="text-align-center mb-2"
      >CRÉDITOS</Typography>

      <div className="row flex-grow-1">
        <div className="col-12 col-sm-6 ">
          <Typography paragraph bold variant='header24'>
            CONCEPÇÃO GERAL DOS JOGOS DE CASOS CLÍNICOS MEDICAL/DENTAL CASES
          </Typography>
          {gameCredits.general_conception.map((e, i) => (
            <Typography paragraph regular variant='header20' key={i}>
              <strong>{e.title}</strong>
              <br />
              {e.text}
            </Typography>
          ))}
        </div>

        <div className="col-12 col-sm-6">
          <Typography variant='header24' bold paragraph>
            PRODUÇÃO DOS JOGOS DE CASOS CLÍNICOS MEDICAL/DENTAL CASE
            </Typography>
          {gameCredits.production.map((e, i) => (
            <Typography paragraph regular variant='header20' key={i}>
              <strong>{e.title}</strong>
              <br />
              {e.text}
            </Typography>
          ))}
        </div>
      </div>

      <div className="text-align-center p-relative">
        <img src={CreditsImage} style={{ height: 64 }} />
      </div>
    </Container>
  );
};

import React from "react";
import { Typography, Button } from "../../components/common";
import { CloseIcon } from "../../assets/icons";

// Services
import { api } from "../../services/api";

export const FileScreen = ({
  file,
  onClose,
  ...props
}) => {
  function handleClose() {
    onClose();
  }

  return (
    <div className="h-75 w-100 d-flex justify-content-center p-relative align-items-start" {...props}>
      <img src={api.defaults.baseURL + file} className="h-100" style={{maxWidth: '90%', objectFit: 'contain'}} />
      <button className="btn-svg ml-2" onClick={handleClose}>
        <CloseIcon style={{ height: 'calc(1.427 * var(--sp))', width: 'calc(1.427 * var(--sp))' }} fill="white" />
      </button>
    </div>
  );
};
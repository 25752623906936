import React from 'react';
// import { appColors, styles } from '../../styles';
import { Container, Typography } from '../../components/common';
import { ArrowIcon } from '../../assets/icons';

// Variables
import { avatars } from '../../variables/Avatars';

export const AvatarScreen = ({ rerender, attendance, ...props }) => {
  const perPage = 3; // Math.floor(windowHeight / 160 - 1);
  const [paginationIndex, setPaginationIndex] = React.useState(0);

  const handlePagination = () => setPaginationIndex(old => old === Math.ceil(avatars.length / 3) - 1 ? 0 : ++old);

  const handleAvatarSelect = (avatar) => () => {
    attendance.setAvatar(avatar);
    rerender();
  }

  return (
    <Container containerClassName="bg-blue" className="menu-container">
      <div className="gray-box">
        <Typography variant="header24">Escolha seu avatar</Typography>
      </div>

      <div className="flex-grow-1 d-flex align-items-center">
        <div className="row flex-grow-1 mr-2">
          {avatars.map((item, index) => {
            if (index < perPage * paginationIndex || index >= perPage * (1 + paginationIndex)) {
              return null;
            }
            return (
              <div className="col-4" key={index}>
                <img
                  className="avatar"
                  onClick={handleAvatarSelect(item)}
                  src={item.image}
                  key={index}
                />
              </div>
            );
          })}
        </div>
        {avatars.length > 3 && (
          <button className="btn-svg ml-2" onClick={handlePagination}>
            <ArrowIcon fill='white' style={{ height: 'calc(4 * var(--sp))'}} />
          </button>
        )}
      </div>
    </Container>
  );
}
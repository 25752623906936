import React from "react";

import { appColors } from "../styles";
import { CloseIcon, ArrowIcon } from "../assets/icons";
import { Typography, Container } from "../components/common";
import { AttendanceRow } from "../components/AttendanceRow";

// Services
import { getPagination } from "../services/utils";
import { api } from "../services/api";

export const HistoryScreen = ({ onClose, ...props }) => {
  const [pageIndex, setPageIndex] = React.useState(0);
  const [casosSaves, setCasosSaves] = React.useState([]);

  React.useEffect(() => {
    api
      .get('/games/attendances')
      .then(response => {
        // Selecionar apenas o atendimento com maior pontuação em cada caso.
        setCasosSaves(response.data);
      })
      .catch(error => {
        console.error(error);
      });
  }, []);

  const handlePagination = () => {
    if (casosSaves ) {
      setPageIndex(getPagination(casosSaves, 2, pageIndex));
    }
  }


  return (
    <Container
      style={{ justifyContent: "center", alignItems: "center" }}
      className={"d-flex justify-content-center align-items-center "}
    >
      <div
        className={"p-relative"}
        style={{
          padding: "calc(2 * var(--sp))",
          backgroundColor: "#fff",
          borderRadius: "calc(2 * var(--sp))",
          width: "80%",
          height: "80%",
        }}
      >
        <button
          className={"btn-svg p-absolute"}
          style={{
            zIndex: 2,
            top: 'calc(2 * var(--sp))',
            right: 'calc(2 * var(--sp))',
          }}
          onClick={onClose}
        >
          <CloseIcon fill={appColors.primary} style={{ height: 'calc(1.427 * var(--sp))', width: 'calc(1.427 * var(--sp))' }} />
        </button>
        <Typography
          variant='header34'
          bold
          style={{ textAlign: "center", marginBottom: "2%", color: appColors.primary }}
        >
          Histórico dos atendimentos
        </Typography>

        {casosSaves.length > 0 ?
          <div
            className={"d-flex align-items-center p-absolute "}
            style={{
              right: 'calc(0.856155 * var(--sp))',
              bottom: 0,
              top: 0,
            }}
            onClick={handlePagination}
          >
            <div
              className={"d-flex align-items-center  p-relative"}
              style={{
                cursor: "pointer",
                margin: 'calc(-0.57077* var(--sp))',
              }}
            >
              <ArrowIcon
                fill='#D5D5D5'
                style={{ height: 'calc(4.566 * var(--sp))', width: 'calc(4.566 * var(--sp))', margin: 'var(--sp)' }}
              />
            </div>
          </div> :
          null
        }

        {casosSaves
          ?.slice(2 * pageIndex, 2 * (1 + pageIndex))
          .map((casoSave, key) => (
            <AttendanceRow casoSave={casoSave} key={key} />
          ))}
      </div>
    </Container>
  );
};
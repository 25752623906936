import React from "react";
import { Button, Container, Typography } from "../../components/common";
import { api } from "../../services/api";

export function IntroductionScreen({ rerender, attendance, caseId, ...props }) {
  const handleClick = () => {
    attendance.setReadyToPlay();
    rerender();
  };

  return (
    <Container
      containerStyle={{ backgroundColor: "black" }}
      style={{
        backgroundImage: `url(${api.defaults.baseURL}/static/images/cases/${caseId}/cenario.png)`,
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
        backgroundSize: 'contain'
      }}
    >
      <div
        className="gray-box"
        style={{
          height: "77.5%",
          padding: "calc(2 * var(--sp))",
          width: "calc(24 * var(--sp))",
          alignItems: "flex-start"
        }}
      >
        <div>
          <Typography variant="header30">

            <p>
              Olá, aluna(o)!
            </p>
            <br />
            Neste jogo, você vai atender usuários inseridos em diferentes espaços de atenção e/ou assistência do Sistema Único de Saúde (SUS).
            As etapas do jogo são <b>anamnese, exame clínico, exames complementares, diagnóstico, conduta/tratamento e comunicação</b>.
            <p>
              O seu desafio é fazer boas escolhas nestas etapas.
            </p>
          </Typography>
        </div>
      </div>

      <img src={attendance.avatar.image} className="h-100" />

      <Button
        disabled={!attendance.case}
        onClick={handleClick}
        style={{
          position: 'absolute',
          right: "calc(2 * var(--sp))",
          bottom: "calc(4 * var(--sp))",
          display: "flex",
          minWidth: 'calc(12 * var(--sp))',
          justifyContent: "center",
        }} >
        <Typography variant="header24" style={{ color: '#f1f2f3' }}>
          {attendance.case ? "Jogar" : "Carregando caso..."}
        </Typography>
      </Button>
      {/* <div
        className="gray-box"
        onClick={handleClick}
        style={{
          position: "absolute",
          right: "calc(2 * var(--sp))",
          bottom: "calc(2 * var(--sp))",
          display: "flex",
          width: 'calc(8 * var(--sp))',
          justifyContent: "center",
          cursor: attendance.case ? "pointer" : "default",
          pointerEvents: attendance.case ? "all" : "none",
        }}
      >

      </div> */}
    </Container>
  );
}

import React from "react";
import PropTypes from "prop-types";

// Screens
import { ConfigurationScreen } from "../dialog/ConfigurationScreen";

// Components
import { Typography, Button, Container, Modal } from "../../components/common";
import { CircleButton } from "../../components/CircleButton";

import { ConfigurationIcon, CreditsIcon, HomeIcon } from "../../assets/icons";

// Services
import { api } from "../../services/api";
import { hash } from "../../services/utils";
import { TextScreen } from "../dialog/TextScreen";

import { HistoryScreen } from "../HistoryScreen";
import { GameCreditsScreen } from "./GameCreditsScreen";

const Column = ({ color, fill, onClick, label }) => {
  const beautifulFill = fill > 1 ? 1 : fill < 0 ? 0 : fill;

  return (
    <div
      className="h-100 d-flex flex-column"
      style={{ flexBasis: "16.66%", minWidth: "16.66%" }}
    >
      <div
        className="d-flex flex-column align-items-center justify-content-end flex-grow-1 cursor-pointer"
        onClick={onClick}
        style={{
          paddingTop: 24,
        }}
      >
        <Typography
          bold
          variant="header20"
          style={{ textAlign: "center", textAlignVertical: "bottom" }}
        >
          {isNaN(beautifulFill) ? 0 : Math.floor(100 * beautifulFill)}%
        </Typography>
        <div
          style={{
            backgroundColor: color,
            height: `${isNaN(beautifulFill) ? 0 : Math.floor(100 * beautifulFill)
              }%`,
            width: "75%",
            borderTopLeftRadius:'calc(0.28 * var(--sp))',
            borderTopRightRadius:'calc(0.28 * var(--sp))'
          }}
        />
      </div>
      <Typography
        bold
        variant="subtitle14"
        style={{
          height: "calc(2 * var(--sp))",
          textAlign: "center",
          paddingTop: 8,
          paddingLeft: 8,
          paddingRight: 8,
        }}
      >
        {label}
      </Typography>
    </div>
  );
};

export const EndingScreen = ({
  rerender,
  attendance,
  reloadCase,
  onNavigate,
  ...props
}) => {

  const [isShowingGraphic, setShowingGraphic] = React.useState(false);
  const [modal, setModal] = React.useState({
    visible: false,
    modal: null,
  });

  React.useEffect(() => {
    const hashCode = hash(`${attendance.case.data.id}-${Date.now()}`);

    api
      .post("/games/attendances", {
        case_id: attendance.case.data.id,
        diagnosis_id: attendance.selections[5]?.id || null,

        anamnesis_score: attendance.data[1]?.score || 0,
        clinical_exam_score: attendance.data[2]?.score || 0,
        diagnosis_score: attendance.data[5]?.score || 0,
        complementary_exam_score: attendance.data[4]?.score || 0,
        conduct_score: attendance.data[6]?.score || 0,
        communication_score: attendance.data[7]?.score || 0,

        anamnesis_duration: attendance.data[1]?.duration || 0,
        clinical_exam_duration: attendance.data[2]?.duration || 0,
        diagnosis_duration: attendance.data[5]?.duration || 0,
        complementary_exam_duration: attendance.data[4]?.duration || 0,
        conduct_duration: attendance.data[6]?.duration || 0,
        communication_duration: attendance.data[7]?.duration || 0,
        
        hash: hashCode,
        interference: attendance.interference
          ? attendance.interference.key
          : null,
        avatar: attendance.avatar.description,
      })
      .catch((error) => {
        console.error(error);
      });

    function keyDownListener(e) {
      e.preventDefault();
      if (e.key === "Escape" || e.key === "Esc") {
        setModal({ visible: false, modal: null });
      }
      return false;
    }

    window.addEventListener("keydown", keyDownListener);

    return () => {
      window.removeEventListener("keydown", keyDownListener);
    };
  }, []);

  function handleAttendanceDetails() {
    setShowingGraphic((old) => !old);
  }

  function handleRedoAttendance() {
    if (reloadCase) {
      reloadCase();
    }
  }

  function handleNewPatient() {
    onNavigate("/categorias");
  }

  function handleAccessHistory() {
    setModal({
      visible: true,
      modal: <HistoryScreen />,
    });
  }

  function handleCaseCredits() {
    setModal({
      visible: true,
      modal: <GameCreditsScreen gameCase={attendance.case} />,
    });
  }

  function handleConfiguration() {
    setModal({
      visible: true,
      modal: <ConfigurationScreen />,
    });
  }

  const handleModalClose = () => {
    setModal({ visible: false });
  };

  const showFeedback = (etapa) => () => {
    setModal({
      visible: true,
      modal: (
        <TextScreen
          width={"calc(51.654685 * var(--sp))"}
          height={"calc(27.68 * var(--sp))"}
          padding={"calc(2 * var(--sp))"}
          title="Feedback da Etapa"
        >
          <div>
            {etapa === 5 ? (
              <div >
                <Typography wrap={"anywhere"} bold variant="header24">
                  {attendance.selections[etapa]?.texto}
                </Typography>
                <Typography wrap={"anywhere"} paragraph variant="header24">
                  {attendance.selections[etapa]?.feedback}
                </Typography>
                <Typography wrap={"anywhere"} bold variant="header24">
                  Referências Bibliográficas
                </Typography>
                <Typography wrap={"anywhere"} paragraph variant="header24">
                  {attendance.selections[etapa]?.reference || "---"}
                </Typography>
              </div>
            ) : (
              attendance.selections[etapa === 7 ? 1 : etapa]?.map((e, k) => (
                <div key={k}>
                  <Typography wrap={"anywhere"} bold variant="header24">
                    {e.text}
                  </Typography>
                  <Typography wrap={"anywhere"} paragraph variant="header24">
                    {e.feedback}
                  </Typography>
                  <Typography wrap={"anywhere"} bold variant="header24">
                    Referências Bibliográficas
                  </Typography>
                  <Typography wrap={"anywhere"} paragraph variant="header24">
                    {e.reference || "---"}
                  </Typography>
                </div>
              ))
            )}
          </div>
        </TextScreen>
      ),
    });
  };

  const columnHeight = 168;
  const marginBottom = -16;

  const modalComponent = (
    <Modal
      visible={modal.visible}
      onClose={() => setModal({ visible: false, modal: null })}
    >
      {modal.modal}
    </Modal>
  );

  return (
    <Container
      overflowChildren={modalComponent}
      containerClassName="bg-gray"
      style={{ padding: "calc(2 * var(--sp))" }}
      className={"d-flex flex-column justify-content-between"}
    >
      {/* Title */}
      <Typography
        paragraph
        bold
        variant={isShowingGraphic ? "header24" : "header34"}
        style={{ textAlign: "center", marginBottom: 16 }}
      >
        {isShowingGraphic
          ? "Clique nas barras para feedback\ndo atendimento"
          : "Fim do atendimento!"}
      </Typography>

      <div
        className={"spaced-row p-absolute"}
        style={{ top: "calc(2 * var(--sp))", right: "calc(2 * var(--sp))" }}
      >
        <CircleButton
          style={{ marginRight: "var(--sp)" }}
          onClick={handleCaseCredits}
        >
          <CreditsIcon className="h-100 w-100" />
        </CircleButton>
        <CircleButton
          style={{ marginRight: "var(--sp)" }}
          onClick={handleNewPatient}
        >
          <HomeIcon className="h-100 w-100" />
        </CircleButton>
        <CircleButton onClick={handleConfiguration}>
          <ConfigurationIcon className="h-100 w-100" />
        </CircleButton>
      </div>

      {/* Content */}
      <div className="row" style={{ height: "80%" }}>
        <div className="col-6">
          <Typography bold variant="header34" style={{ textAlign: "center" }}>
            Pontuação
          </Typography>
          <Typography bold variant="header34" style={{ textAlign: "center" }}>
            {Object.values(attendance.data)
              .filter((v,k) => k!=2)
              .map((s) => s.score)
              .reduce((prev, curr) => prev + curr, 0)
              .toLocaleString("pt-br")}
          </Typography>

          <div
            className='d-flex flex-column flex-grow-1 justify-content-space-between'
            style={{ marginTop: 'var(--sp)' }}
          >
            <Button
              className="paragraph ending-screen-btn mb-1b"
              onClick={handleAttendanceDetails}
            >
              {isShowingGraphic ? 'Mostrar Paciente' : 'Detalhes do atendimento'}
            </Button>
            <Button
              className="paragraph ending-screen-btn mb-1b"
              onClick={handleRedoAttendance}
            >
              Refazer o atendimento
            </Button>
            <Button
              className="paragraph ending-screen-btn mb-1b"
              onClick={handleNewPatient}
            >
              Novo paciente
            </Button>
            <Button
              className="paragraph ending-screen-btn"
              onClick={handleAccessHistory}
            >
              Histórico
            </Button>
          </div>
        </div>

        <div className="col-6 d-flex justify-content-end ">
          {isShowingGraphic ? (
            <div className={"h-100 w-100 d-flex p-2"} style={{ borderRadius: 'var(--sp)', backgroundColor:'white' }}>
              <Column
                color="#97BED6"
                onClick={showFeedback(1)}
                fill={
                  !attendance.data[1]
                    ? 0
                    : attendance.data[1].score / attendance.data[1].maxScore
                }
                label="Anamnese"
                mb={marginBottom}
              />
              <Column
                color="#AFAFAD"
                onClick={showFeedback(2)}
                fill={
                  !attendance.data[2]
                    ? 0
                    : attendance.data[2].score / attendance.data[2].maxScore
                }
                label="Exame Clínico"
                mb={marginBottom}
              />
              <Column
                color="#D55960"
                onClick={showFeedback(4)}
                fill={
                  !attendance.data[4]
                    ? 0
                    : attendance.data[4].score / attendance.data[4].maxScore
                }
                label="Exame Complementar"
                height={columnHeight}
                mb={marginBottom}
              />
              <Column
                color="#86BD7C"
                onClick={showFeedback(5)}
                fill={
                  !attendance.data[5]
                    ? 0
                    : attendance.data[5].score / attendance.data[5].maxScore
                }
                label="Diagnóstico"
                height={columnHeight}
                mb={marginBottom}
              />
              <Column
                color="#FBD174"
                onClick={showFeedback(6)}
                fill={
                  !attendance.data[6]
                    ? 0
                    : attendance.data[6].score / attendance.data[6].maxScore
                }
                label="Conduta"
                height={columnHeight}
                mb={marginBottom}
              />
              <Column
                color="#ACA0BE"
                onClick={showFeedback(7)}
                fill={
                  !attendance.data[7]
                    ? 0
                    : attendance.data[7].score / attendance.data[7].maxScore
                }
                label="Comunicação"
                height={columnHeight}
                mb={marginBottom}
              />
            </div>
          ) : (
            <div className="d-flex flex-column align-items-center flex-grow-1 justify-content-end">
              <Typography paragraph variant="header20">
                {attendance.case.data.patient_name}
              </Typography>
              <img
                src={
                  api.defaults.baseURL +
                  `/static/images/cases/${attendance.case.data.id}/paciente-prontuario.png`
                }
                alt={
                  api.defaults.baseURL +
                  `/static/images/cases/${attendance.case.data.id}/paciente-prontuario.png`
                }
                style={{
                  height: "calc(19 * var(--sp))",
                  widows: "calc(19 * var(--sp))",
                }}
              />
            </div>
          )}
        </div>
      </div>
    </Container>
  );
};

EndingScreen.propTypes = {
  rerender: PropTypes.func.isRequired,
  attendance: PropTypes.object.isRequired,
};

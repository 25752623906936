import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

export function CircleButton({
  size,
  onClick,
  children,
  className,
  style,
  ...props
}) {
  return (
    <button
      className={classnames("btn-circle", className)}
      onClick={onClick}
      style={{
        ...style,
        width: size,
        height: size
      }}
      {...props}
    >
      {children}
    </button>
  );
};

CircleButton.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClick: PropTypes.func.isRequired
};

CircleButton.defaultProps = {
  size: 'calc(2 * var(--sp))'
};

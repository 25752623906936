import React from "react";
import { api } from "../services/api";

import { Typography } from "./common";

const Row = ({ label, value, ...props }) => {
  return (
    <div style={{ flexDirection: "row" }} {...props}>
      <Typography variant='header24' bold>{`${label} `}</Typography>
      <Typography variant='header24'>{value}</Typography>
    </div>
  );
};

export const AttendanceRow = ({ casoSave, ...props }) => {
  return (
    <div style={{ display: "flex", flexDirection: "row", marginBottom: 'calc(1.0702 * var(--sp))' }} {...props}>
      <img
        src={api.defaults.baseURL + `/static/images/cases/${casoSave.case_id}/paciente-prontuario.png`}
        className="gc-history-img"
      />
      <div style={{ flexGrow: 1, flexShrink: 1 }}>
        <Typography paragraph variant='header24'><strong>Data: </strong>{formatDate(casoSave.created_at)}</Typography>
        <Typography paragraph variant='header24'><strong>Paciente: </strong>{casoSave.patient_name}</Typography>
        <Typography paragraph variant='header24'><strong>Diagnóstico: </strong>{casoSave.diagnosis || '---'}</Typography>
        <Typography paragraph variant='header24'><strong>Pontuação: </strong>{casoSave.final_score?.toLocaleString("pt-br")}</Typography>
      </div>
    </div>
  );
};

function formatDate(date) {
  if (!date) {
    return null;
  }

  let validDate = date;
  if (typeof date === "string") {
    validDate = new Date(validDate);
  }

  const pad = t => new String(t).padStart(2,"0");
  
  const a = new Date();
  a.getDay()
  return `${pad(validDate.getDate())}/${pad(1+validDate.getMonth())}/${validDate.getFullYear()} ${pad(validDate.getHours())}:${pad(validDate.getMinutes())}`
}
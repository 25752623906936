import React from "react";
import PropTypes from "prop-types";

import classnames from "classnames";

// Components
import { Typography } from "./common";

import "./CaseCard.css";

export const CaseCard = ({ title, image, onClick }) => {
  return (
    <div onClick={onClick} className="case-card">
      <div className="case-card-image">
        <img
          src={image}
          // defaultSource={defaultSource}
        />
      </div>
      <div className="case-card-text-container">
        <Typography bold variant='subtitle16' style={{ textAlign: "center" }}>
          {title}
        </Typography>
      </div>
    </div>
  );
};

CaseCard.propTypes = {
  title: PropTypes.string,
};

CaseCard.defaultProps = {
  title: "",
};

import React from "react";
import { Redirect } from "react-router-dom";
import { Typography, Button, TextInput } from "../../components/common";

// Context
import { AuthContext } from "../../contexts/AuthContext";

// Services
import { api } from "../../services/api";

const { gameName, gameProfessionalName } = require('../../config/game.config.json');

export const SignUpScreen = ({ history, location, ...props }) => {
  const { signIn } = React.useContext(AuthContext);
  const [busy, setBusy] = React.useState(false);

  if (!location.state) {
    return <Redirect to='/' />
  }

  const { user, token } = location.state;
  
  function handleSubmit(a) {
    try {
      const nickname = document.getElementById("signup-profile-nickname").value;
      if (nickname) {
        setBusy(true);
        api.post('/accounts/profiles', { nickname }, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })
          .then(() => {
            signIn({ token });
            history.push('/');
          })
          .catch(error => {
            setBusy(false);
            console.error(error);
          });
      } else {
        // Exibir mensagem de erro
      }
    } catch ({ message }) {
      console.error(message);
    }
  }

  return (
    <div className="d-flex flex-column">
      <Typography paragraph regular variant="header20">Bem vindo ao {gameName}, {user.name.split(' ')[0]}!</Typography>
      <Typography paragraph variant="subtitle14">Para começar, escolha seu nome de {gameProfessionalName.toLowerCase()}.</Typography>
      <form className="d-flex flex-column align-items-stretch">
        <TextInput id="signup-profile-nickname" type="text" placeholder={`Nome de ${gameProfessionalName}`} className="mb-2" />

        <Button onClick={handleSubmit} disabled={busy}>Criar conta e jogar</Button>
      </form>
    </div>
  );
};

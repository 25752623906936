import Avatar000 from "../assets/images/avatars/avatar_000.png";
import Avatar001 from "../assets/images/avatars/avatar_001.png";
import Avatar002 from "../assets/images/avatars/avatar_002.png";
import Avatar003 from "../assets/images/avatars/avatar_003.png";
import Avatar004 from "../assets/images/avatars/avatar_004.png";
import Avatar005 from "../assets/images/avatars/avatar_005.png";

export const avatars = [
  {
    image: Avatar000,
    description: "adulto-grau-1 feminino cisgenero branco neutro"
  },
  {
    image: Avatar001,
    description: "adulto-grau-1 feminino cisgenero pardo neutro"
  },
  {
    image: Avatar002,
    description: "adulto-grau-1 masculino cisgenero preto neutro"
  },
  {
    image: Avatar003,
    description: "adulto-grau-2 masculino cisgenero indigena neutro"
  },
  {
    image: Avatar004,
    description: "adulto-grau-3 feminino cisgenero preto neutro"
  },
  {
    image: Avatar005,
    description: "adulto-grau-3 masculino cisgenero amarelo neutro"
  }
]
import React from 'react';
import { CloseIcon, ArrowIcon } from '../../assets/icons';
import { Typography, Container } from '../../components/common';
import { getPagination } from '../../services/utils';

import classnames from 'classnames';

const { medicalRecordPages, phases } = require('../../config/game.main.json');

function removeDuplicates(list) {
  if (!list) {
    return null;
  }
  const ids = [];
  const r = [];
  list.forEach(e => {
    if (!ids.includes(e.id)) {
      r.push(e);
      ids.push(e.id);
    }
  });
  return r;
}

export function MedicalRecordScreen({
  attendance,
  page,
  onClose,
  ...props
}) {
  const [paginationIndex, setPaginationIndex] = React.useState(page + 2 || 0);

  function handleClose() {
    if (onClose) {
      onClose();
    }
  }
  const handlePagination = () => setPaginationIndex(getPagination(medicalRecordPages, 1, paginationIndex));

  const phaseKey = medicalRecordPages[paginationIndex].phaseKey;

  return (
    <Container
      containerStyle={{ backgroundColor: '#00968A' }}
      className="medical-record"
      {...props}
    >
      <div className="medical-record-panel">
        <Typography bold variant="header34" style={{ marginBottom: 24 }}>PRONTUÁRIO</Typography>
        {/* Sumário */}
        {paginationIndex === 0 && (
          medicalRecordPages.filter(i => i.label).map((item, key) => {
            return (
              <Typography paragraph bold variant="header30"
                className="cursor-pointer"
                onClick={() => setPaginationIndex(key + 1)}
                key={key}
              >{item.label}</Typography>
            );
          })
        )}

        {/* Informações do paciente */}
        {paginationIndex === 1 && (
          <div style={{ flexShrink: 1 }}>
            <Typography bold paragraph variant="header30">Informações do paciente</Typography>

            <Typography regular paragraph variant="header24"><strong>Nome:</strong> {attendance.case.data.patient_name}</Typography>
            {attendance.case.data.patient_age !== null && <Typography regular paragraph variant="header24 "><strong>Idade:</strong> {attendance.case.data.patient_age}</Typography>}
            {attendance.case.data.patient_days_of_life !== null && <Typography regular paragraph variant="header24  "><strong>Dias de Vida:</strong> {attendance.case.data.patient_days_of_life}</Typography>}
            {attendance.case.data.patient_sex === 'M' && <Typography regular paragraph variant="header24  "><strong>Sexo:</strong> Masculino</Typography>}
            {attendance.case.data.patient_sex === 'F' && <Typography regular paragraph variant="header24  "><strong>Sexo:</strong> Feminino</Typography>}
            {attendance.case.data.patient_sex !== 'M' && (
              attendance.case.data.patient_is_pregnant && (<Typography regular paragraph variant="header24  "><strong>Meses de Gravidez:</strong> {attendance.case.data.patient_months_of_pregnancy}</Typography>)
            )}
          </div>
        )}

        {/* Histórico de doenças */}
        {paginationIndex === 2 && (
          <div style={{ flexShrink: 1 }}>
            <Typography bold paragraph variant="header30">Histórico de doenças</Typography>
            <Typography regular paragraph variant="header24" style={{ textAlign: "justify" }}>{attendance.case.data.patient_history || "---"}</Typography>
            <Typography bold paragraph variant="header30">Histórico familiar</Typography>
            <Typography regular paragraph variant="header24">{attendance.case.data.patient_family_history || "---"}</Typography>
          </div>
        )}

        {/* Anamnese e demais etapas */}
        {paginationIndex >= 3 && (
          <div style={{ overflow: "hidden", flexGrow: 1 }}>
            <Typography bold paragraph variant="header30">{medicalRecordPages[paginationIndex].label}</Typography>
            {paginationIndex === 6
              ? <>
                <Typography paragraph variant="header24">Hipótese diagnóstica</Typography>
                <Typography regular paragraph variant="header24">{attendance.selections[phaseKey]?.text || '---'}</Typography>
              </>
              : (
                removeDuplicates(attendance.selections[phaseKey])?.map((op, index) =>
                  <Typography regular paragraph variant="header24" key={index}>{`• ${op.medical_record_text || op.text}`}</Typography>
                )
              )
            }
          </div>
        )}
      </div>

      {/* Coluna direita */}
      <div className="d-flex align-items-center flex-grow-1 p-relative text-white flex-column">
        <div className="h-60 d-flex flex-column justify-content-end" style={{ alignItems: 'center' }}>
          {paginationIndex >= 3 && (
            <>
              <Typography bold variant="header34" color='#fff' style={{ textAlign: 'center' }}>{medicalRecordPages[paginationIndex].label}</Typography>
              <Typography paragraph variant="header24" color='#fff'>Pontuação</Typography>
              <Typography paragraph variant="header34" color='#fff'>{attendance.getScore(phaseKey).toLocaleString('pt-br')}</Typography>
            </>
          )}
        </div>

        <div className="h-40" style={{ alignItems: 'center', marginBottom: 'calc(2.57 *var(--sp))', marginTop: 'var(--sp)' }}>
          <button className="btn-svg" onClick={handlePagination}>
            <ArrowIcon fill='#fff' style={{ height: 'calc(4 * var(--sp))' }} />
          </button>
        </div>

        {/* Botão de fechar */}
        <button className="btn-svg p-absolute" onClick={handleClose} style={{ right: 0, top: 0 }}>
          <CloseIcon fill='#025850' style={{ height: 'calc(1.427 * var(--sp))', width: 'calc(1.427 * var(--sp))' }} />
        </button>
      </div>
    </Container>
  );
}
import React from "react";
import { Link, Redirect } from "react-router-dom";

import { Container, Typography } from "../components/common";
// import { ThemeContext } from "../ThemeContext";

import { motion, useMotionValue, animate, useTransform } from "framer-motion";
import { appColors } from "../styles";
import queryString from "query-string";

import "./SplashScreen.css";

// imgs
import Icon from "../assets/images/brand/brand.png";
import BannerName from "../assets/images/brand/brand-name.png";

const brandPaths = require("../config/game.brand.json");

const AnimatedStroke = ({
  d,
  progress,
  strokeDasharray,
  stroke,
  rotate,
  ...props
}) => {
  return (
    <motion.path
      fill="none"
      d={d}
      stroke={stroke}
      strokeWidth={6}
      strokeDasharray={strokeDasharray}
      strokeDashoffset={progress}
      strokeLinejoin={"round"}
      {...props}
    />
  );
};

export const SplashScreen = ({ history, location, ...props }) => {
  const stokeAnim = useMotionValue(2000);
  const fadeOutAnim = useMotionValue(1);
  const moveAnim = useMotionValue(0);
  const fadeInOutAnim = useMotionValue(0);

  const [resolution, setResolution] = React.useState(null);

  function updateResolution() {
    setResolution(Math.min(1.5625 * window.innerWidth, 2.77 * window.innerHeight) / 45600 * 13.2347);
    window.onresize = function () {
      if (document.getElementsByTagName("html").length !== 0) {
        setResolution(Math.min(1.5625 * window.innerWidth, 2.77 * window.innerHeight) / 45600 * 13.2347);
      }
    };
  }

  React.useEffect(() => {
    const query = queryString.parse(location.search);

    if (query.play) {
      return history.push("/jogar/" + query.play);
    }

    if (location.state?.case) {
      history.push({
        pathname: "/jogar",
        state: location.state,
      });
    }
  }, []);

  React.useEffect(() => {
    const circleAnimDuration = 2.6;

    const animation = [
      animate(stokeAnim, 0, {
        stiffness: 2000,
        duration: circleAnimDuration,
        onPlay: (v) => {
          animate(fadeOutAnim, 0, {
            delay: circleAnimDuration - 0.9,
            duration: 0.5,
          });
        },
        onComplete: (v) => {
          animate(moveAnim, 1, {
            duration: 1,
          });
        },
      }),

      animate(fadeInOutAnim, 1, {
        duration: 0.7,
        yoyo: Infinity,
      }),
    ];

    updateResolution();

    return animation.stop;
  }, []);

  return (
    <Link to="/categorias" style={{ cursor: "default" }}>
      <Container
        containerClassName="gamecase-bg-default"
        className="d-flex flex-column align-items-center"
      >
        <motion.div
          className={"icons-container"}
          animate={{ transform: "translateY(calc(-1.71231 * var(--sp))" }}
          transition={{ delay: 2.3, duration: 1, ease: "linear" }}
          style={{
            alignItems: "center",
            transform: "translateY(var(--sp))"
            // translateY: useTransform(moveAnim, [0, 1], [0, - 6 * 8]),
          }}
        >
          <div className={"splash-logo"}>
            <motion.div
              className={"splash-logo splash-anim"}
              style={{
                opacity: fadeOutAnim,
              }}
            >
              {brandPaths.map(({ d, position, size }, k) => (
                <div
                  style={{
                    position: "absolute",
                    top: position.top * resolution,
                    left: position.left * resolution,
                  }}
                  key={k}
                >
                  <motion.svg
                    viewBox={`0 0 ${size.width} ${size.height}`}
                    width={size.width * resolution}
                    height={size.height * resolution}
                  >
                    <AnimatedStroke
                      progress={stokeAnim}
                      d={d}
                      stroke={appColors.stokeBlue}
                      strokeDasharray={2000}
                    />
                  </motion.svg>
                </div>
              ))}
            </motion.div>

            <motion.div
              className="splash-logo"
              style={{
                opacity: useTransform(fadeOutAnim, [0, 1], [1, 0]),
              }}
            >
              <img src={Icon} className={"splash-logo image-icon"} />
            </motion.div>
          </div>
          <motion.div
            className={"banner-container"}
            style={{ opacity: moveAnim, height: 'var(--sp)', margin: 'calc(1.14154 * var(--sp))' }}
          >
            <img src={BannerName} className={"banner-icon"} />
            <Typography
              variant={"header34"}
              style={{
                textAlign: "center",
                color: appColors.highEmphasisWhiteText,
                margin: 'var(--sp)',
              }}
            >
              JOGOS DE CASOS CLÍNICOS
            </Typography>
          </motion.div>
        </motion.div>

        <motion.div
          className={"bottom-text-container"}
          style={{
            opacity: moveAnim,
            backgroundColor: appColors.mediumEmphasisBlackText,
          }}
        >
          <motion.div
            style={{
              textAlign: "center",
              opacity: fadeInOutAnim,
            }}
          >
            <Typography
              variant={"header24"}
              style={{ color: appColors.highEmphasisWhiteText, margin: 'calc(0.57077* var(--sp))' }}
            >
              TOQUE NA TELA PARA INICIAR
            </Typography>
          </motion.div>
        </motion.div>
      </Container>
    </Link>
  );
};

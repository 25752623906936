import React from "react";

import { Button } from "../../components/common";
import { WindowOpener } from "../../services/window-opener";

// Context
import { AuthContext } from "../../contexts/AuthContext";

// Services
import { api } from "../../services/api";

const { gameCaseUrl, gameCode } = require("../../config/game.config.json");

export const SignInScreen = ({ history, location, ...props }) => {
  const { signIn } = React.useContext(AuthContext);

  React.useEffect(() => {
    window.addEventListener("message", receiveMessage, { once: true });

    return () => {
      window.removeEventListener("message", receiveMessage);
    };
  }, []);

  function receiveMessage(event) {
    event.stopPropagation();

    // if (event.origin.substring(event.origin.indexOf("//")) !== gameCaseUrl) {
    //   return alert("Operação inválida.");
    // }
    const { data } = event;

    if (data.error) {
      console.error(data.error);
      return;
    }

    if (!data.user.profile_id) {
      return history.push("/cadastrar", {
        user: data.user,
        token: data.token,
      });
    }
    signIn({ token: data.token });
  }

  return (
    <div id="message-receiver" className="d-flex">
      <WindowOpener
        url={
          (
            process.env.NODE_ENV === "production"
            ? gameCaseUrl
            : "//localhost:3000") +
          `/public/api/entrar?origin=${document.URL}&gameCode=${gameCode}`
        }
        opts="resizable=no, scrollbars=no"
        width={400}
        height={600}
      >
        <Button className="w-100">Jogar Agora</Button>
      </WindowOpener>
    </div>
  );
};

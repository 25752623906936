import React from "react";
import classnames from "classnames";

import "./Container.css";

const Container = ({
  children,
  className,
  containerStyle,
  containerClassName,
  style,
  overflowChildren,
  ...props
}) => {
  return (
    <div
      className={classnames('fullscreen align-center', containerClassName)}
      style={containerStyle}
      {...props}
    >
      {/* <div className={classnames("main-inside-container", className, style)}> */}
      <div className={classnames("gc-container p-relative", className)} style={style}>
        {children}
      </div>
      {/* </div> */}
      {overflowChildren}
    </div>
  );
};

export default Container;
import React from 'react';
// import { measure } from './tools/resolution';

// 16:9 Grid: 64x36m
// where 1m = 30px

export const appColors = {
  primary: "#25A186",
  secondary: "#435D83",
  orange: '#F28044',
  backgroundBlue: '#1F688C',
  cardBackground: '#77BEB3',
  cardGray: '#DDE0E3',
  activePhase: '#FF8040',
  highEmphasisBlackText: "#000000DE",
  mediumEmphasisBlackText: "#0000008A",
  disabledBlackText: "#FFFFFF61",
  highEmphasisWhiteText: "#FFFFFFDE",
  mediumEmphasisWhiteText: "#FFFFFF8A",
  stokeBlue: "#007ACD",
  warning: '#dc3545'

};

// export const styles = {
//   flex: {
//     flex: 1,
//     // backgroundColor: 'purple'
//   },
//   container: {
//     flex: 1,
//     flexDirection: "row",
//     backgroundColor: "white",
//     justifyContent: "center",
//     alignItems: "center",
//     // paddingHorizontal: 75,
//     // paddingVertical: 45
//   },
//   spacedRow: {
//     flexDirection: 'row',
//     justifyContent: 'space-between'
//   },
//   center: {
//     justifyContent: 'center',
//     alignItems: 'center'
//   },
//   dropShadowCircle: {
//     shadowOffset: { width: 0, height: 0 },
//     shadowOpacity: 0.5,
//     shadowRadius: 10,
//     borderRadius: 2
//   },
//   dropShadow: {
//     // shadowOffset: { width: 0, height: 0 },
//     shadowOpacity: 0.5,
//     shadowRadius: 10,
//   },
//   banner: {
//     width: 460,
//     height: 280,
//     marginVertical: 32
//   },
//   textInput: {
//     display: 'flex',
//     paddingLeft: 8,
//     paddignRight: 8,
//     marginBottom: 8,
//     borderRadius: 2,
//     height: 48,
//     border: 'thin solid',
//     backgroundColor: "#D7D7D7",
//     borderColor: "#a1a1a1",
//   },
//   underscored: {
//     textDecorationStyle: 'solid',
//     textDecorationLine: 'underline',
//   },
//   absolutePosition: {
//     position: "absolute",
//     bottom: 0,
//     top: 0,
//     left: 0,
//     right: 0,
//   },
//   componentButtonStyle: {
//     display: 'flex',
//     height: 48,
//     cursor: 'pointer',
//     borderRadius: 8,
//     border: 'none',
//     justifyContent: "center",
//     alignItems: "center",
//     overflow: 'hidden',
//     backgroundColor: appColors.primary,
//   },
//   componentButton: {
//     padding: 16,
//     flex: 1,
//     backgroundColor: appColors.primary,
//   },
//   componentButtonDisabled: {
//     justifyContent: "center",
//     alignItems: "center",
//     padding: 16,
//     backgroundColor: '#84D0C3',
//   },
//   componentButtonWithIcon: {

//     paddingRight: 32,
//     paddingLeft: 16,
//     paddingVertical: 16,
//     alignItems: 'center',
//   },

//   componentPhaseButtonContainer: {
//     alignItems: 'stretch',
//     flexGrow: 1
//   },
//   position: 'relative',
//   backgroundColor: "white",
//   shadowColor: "#000",
//   shadowOffset: {
//     width: 0,
//     height: 2
//   },
//   modal: {
//     display: 'flex',
//     backgroundColor: '#000000D3',
//     position: 'absolute',
//     left: 0,
//     right: 0,
//     bottom: 0,
//     top: 0,
//     justifyContent: "center",
//     alignItems: "center"
//   },
// };
import React from "react";
import { Typography, Button } from "../../components/common";
import { CloseIcon } from "../../assets/icons";

export const AlertScreen = ({
  message,
  onCancel,
  onConfirm,
  onClose,
  ...props
}) => {
  function handleClose() {
    if (onCancel) {
      onCancel();
    } else {
      onClose();
    }
  }

  return (
    <div className="alert-modal" {...props}>
      <button
        className="btn-svg alert-close"
        onClick={onClose}
      >
        <CloseIcon style={{ height: 'calc(1.427 * var(--sp))', width: 'calc(1.427 * var(--sp))' }} fill='var(--black)' />
      </button>
      <Typography bold variant='header34' style={{ textAlign: "center" }}>
        {message}
      </Typography>
      <div className={"alert-btn-container"}>
        <Button className={"alert-btn"} onClick={onConfirm}>Sim</Button>
        <Button className={"alert-btn"} onClick={handleClose}>Não</Button>
      </div>
    </div>
  );
};

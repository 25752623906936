import React from "react";
import { Redirect } from "react-router-dom";

// Services
import { api } from "./services/api";

export const PlayRedirect = ({ history, ...props }) => {
  const [redirect, setRedirect] = React.useState();

  React.useEffect(() => {
    const { case_slug } = props.match.params;

    api
      .get(`/games/cases?slug=${case_slug}&first=true&head=true`)
      .then((response) => {
        if (response.data) {
          setRedirect({
            pathname: "/jogar",
            state: {
              caseId: response.data.id,
            },
          });
        } else {
          setRedirect("/");
        }
      })
      .catch((error) => {
        console.error(error);
        setRedirect(`/?play=${case_slug}`);
      });
  }, []);

  if (!redirect) {
    return null;
  }

  return <Redirect to={redirect} />;
};

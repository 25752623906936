import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { motion } from "framer-motion";

import Typography from "./Typography";

import "./Button.css";

function Button({
  disabled,
  className,
  onClick,
  children,
  fontVariant,
  textStyle,
  ...props
}) {
  const classes = classnames("btn", className, {
    "btn-disabled": disabled
  });

  function handleClick(e) {
    e.preventDefault();
    if (onClick) {
      onClick(e);
    }
  }

  return (
    // <div
    //   className={classnames("btn-container-style", className)}
    //   disabled={disabled}
    // >
    <button
      onClick={handleClick}
      className={classes}
      {...props}
    >
      <Typography
        bold
        variant={fontVariant || "button14"}
        {...textStyle}
      >
        {children}
      </Typography>
    </button>
    // </div>
  );
}

Button.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  fontVariant: Typography.propTypes.variant,
  textStyle: PropTypes.object
};

Button.defaultProps = {
  disabled: false,
  textStyle: {}
};

export default Button;
import { shuffle } from "../utils";
import { GameCase } from "./GameCase";

export class Attendance {
  constructor() {
    this.avatar = null;
    this.case = null;
    this.selections = {};
    this.data = {};
    this.finalScore = null;

    this._aux = {
      lastCommunication: null
    };
  }

  setGameCase(gameCase) {
    this.case = new GameCase(gameCase);
  }

  startPhase(phase) {
    let options = this.case.getOptions(phase === 5 ? 3 : phase);

    if (phase === 3 || phase === 5) {
      options = [
        shuffle(options.filter(d => d.type === 0))[0],
        shuffle(options.filter(d => d.type === 1))[0],
        shuffle(options.filter(d => d.type === 2))[0]
      ];
    }

    this.data[phase] = {
      score: 0,
      options,
      maxScore: GameCase.getMaxScore(options, phase)
    };

    this.selections[phase] = null;

    if (phase === 3) {
      this.data[5] = this.data[3];
      this.selections[5] = null;
    }
  }

  getOptions(phase) {
    if (!this.data[phase]) {
      this.startPhase(phase);
    }
    return this.data[phase].options;
  }

  addOption(option, phase, index) {
    if (!this.data[phase]) {
      this.startPhase(phase);
    }

    if (phase === 3 || phase === 5 || phase === 7) {
      this.data[phase].options.forEach(element => { element.checked = false; });
      this.selections[phase] = option;
      if (phase === 3) {
        this.selections[5] = option;
      }
    } else {
      if (!this.selections[phase]) {
        this.selections[phase] = [];
      }
      this.selections[phase].push(option);
    }
    this.data[phase].options[index].checked = true;

    this.calculateScore(option, phase);
  }

  calculateScore(option, phase) {
    const score = GameCase.getScore(option, phase);
    if (phase === 3 || phase === 5) {
      this.data[phase].score = score;
    } else if (phase === 7) {
      // Deve-se remover os pontos da última opção selecionada antes de adicionar os novos pontos
      if (this._aux.lastCommunication) {
        this.data[phase].score -= GameCase.getScore(this._aux.lastCommunication, 7);
      }
      this.data[phase].score += score;
      this._aux.lastCommunication = option;
    } else {
      this.data[phase].score += score;
      // Cálculo de pontuação customizado para anamnese
      if (phase === 1 && (option.type === 0 || option.type === -1)) {
        // Inicia a fase 7 (Comunicação)
        if (!this.data[7]) {
          this.startPhase(7);
        }

        const opIdCount = this.selections[phase].map(o => o.id).filter(x => x === option.id).length;
        // Se opIdCount = 2, então o usuário selecionou tanto a opção gentil quanto a rude
        // Nesse caso, subtraí-se a pontuação da opção gentil
        if (opIdCount === 2) {
          this.data[phase].score -= 20000;
          this.data[7].score -= 20000;
        } else {
          // Caso contrário, adiciona-se o valor da opção na pontuação da comunicação
          this.data[7].score += score;
        }
      }
    }
  }

  getScore(phase) {
    if (this.selections[phase]) {
      return this.data[phase].score;
    }
    return 0;
  }

  getMaxScore(phase) {
    return this.data[phase] ? this.data[phase].maxScore : 1;
  }

  setAvatar(avatar) {
    this.avatar = avatar;
  }

  setReadyToPlay() {
    this.ready = true;
  }

  buildInterference() {
    this.interference = this.case.getRandomInterference();
    this.interference.state = 0;

    // Interference states
    // 0 -> Não iniciada
    // 1 -> Ocorrendo
    // 2 -> Finalizada
    // 3 -> Acaba no final
    // 99 -> Tela de interferência
  }
}
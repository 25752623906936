import React from "react";
import PropTypes from "prop-types";

import classnames from "classnames";

function TextInput({
  type,
  placeholder,
  className,
  ...props
}) {
  const classes = classnames(className);
  
  return (
    <input
      className={classes}
      placeholder={placeholder}
      type={type}
      {...props}
    />
  );
};

TextInput.propTypes = {
  placeholder: PropTypes.string,
  type: PropTypes.oneOf([
    "text",
    "email",
    "checkbox",
    "password"
  ])
}

TextInput.defaultProps = {
  type: 'string'
}

export default TextInput;
import React from 'react';

// Components
import { Typography } from '../../components/common';

// Extras
import { CloseIcon } from '../../assets/icons';

import { appColors } from '../../styles';

export function TextScreen({
  title,
  width,
  height,
  padding,
  children,
  onClose,
  ...props
}) {
  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  return (
    <div
      className="d-flex align-items-center flex-column"
      style={{ width, height, padding, backgroundColor: "white", position: 'relative', borderRadius: 'calc(2* var(--sp))' }}
      {...props}
    >
      <button className="btn-svg"
        style={{
          position: 'absolute',
          right: 'calc(2 * var(--sp))',
          top: 'calc(2 * var(--sp))'
        }}
        onClick={handleClose}
      >
        <CloseIcon style={{ height: 'calc(1.427 * var(--sp))', width: 'calc(1.427 * var(--sp))' }} fill={appColors.primary} />
      </button>
      <Typography
        bold
        paragraph
        variant='header34'
        style={{ textAlign: "center", color: appColors.primary }}
      >{title}</Typography>
      <div className="w-100" style={{ overflow: 'scroll', width: '100%', height: '100%' }}>
        {children}
      </div>
    </div>
  );
};
import React from 'react';

// Components
import { Container, Typography } from '../../components/common';
import { PlayerMessageBox } from '../../components/gameplay/PlayerMessageBox';

import { styles, appColors } from '../../styles';

// Services
import { shuffle } from '../../services/utils';

// Images
import ThinkCircles from '../../assets/images/think-circles.png';
import { api } from '../../services/api';

const ImageContainer = ({ children, attendance, phaseKey }) => {
  if (attendance.interference.key === "PACIENTE_DESCONTROLE_EMOCIAL") {
    return (
      <div className="bg-image fullscreen" style={{
        backgroundColor: "#EC2529",
        backgroundImage: `url(${api.defaults.baseURL}/static/images/cases/${attendance.case.data.id}/interferencia-paciente.png)`
      }}>
        {children}
      </div>
    );
  }

  return (
    <div className="bg-image fullscreen" style={{ backgroundImage: attendance.case.getBackroundUrl(phaseKey) }}>
      <div className="bg-image fullscreen" style={{ backgroundImage: attendance.case.getPatientUrl(phaseKey, 0) }}>
        <div className="fullscreen" style={{ backgroundColor: attendance.interference.key === "FALTA_LUZ" ? '#000000A0' : "#EC2529A0" }}>
          {children}
        </div>
      </div>
    </div>
  );
}

export const InterferenceScreen = ({ attendance, phaseKey, onSelect }) => {
  const [arrowPagination, setArrowPagination] = React.useState(0);
  const [options, setOptions] = React.useState();
  const [selectedOption, setSelectOption] = React.useState();

  const { interference } = attendance;

  React.useEffect(() => {
    const interference = attendance.interference;

    setArrowPagination(interference.inner_voice ? 0 : 1);
    setOptions(shuffle(interference.responses));
  }, []);

  const handleOptionClick = (optionIndex) => {
    setSelectOption({ option: interference.responses[optionIndex], extraAction: null });
    setOptions(old => old.map((o, i) => ({ ...o, checked: i === optionIndex })));
  }

  function handleArrowClick() {
    setArrowPagination(old => ++old);
    if (arrowPagination !== 0 && selectedOption) {
      if (onSelect) {
        onSelect(selectedOption);
      }
    }
  }

  const isSpeech = arrowPagination !== 0 && interference.is_speech;

  console.log(options);

  return (
    <Container containerStyle={{ backgroundColor: 'black' }}>
      <ImageContainer attendance={attendance} phaseKey={phaseKey}>
        {interference?.screen_text && (
          <div className="interference-screen-text">
            <Typography bold
              variant="header34"
              style={{ width: '80%', textAlign: 'center', color: appColors.highEmphasisWhiteText }}
            >
              {interference.screen_text}
            </Typography>
          </div>
        )}

        <div className="player-box" style={{ bottom: 'var(--sp)' }}>
          <div>
            {/* Caixa de fala e pensamento do jogador */}
            <PlayerMessageBox
              arrowBlink={arrowPagination === 0 || selectedOption}
              isSpeech={isSpeech}
              onArrowClick={handleArrowClick}
              onOptionClick={handleOptionClick}
              options={options}
              showArrow={arrowPagination === 0 || selectedOption}
              showText={arrowPagination === 0}
              text={interference.inner_voice}
            />

            {/* Imagem do médico */}
            <div className="p-absolute avatar base-shadow">
              <img src={attendance.avatar.image} className="avatar" />
              {!isSpeech && <img src={ThinkCircles} className="think-circles" />}
            </div>
          </div>
        </div>

      </ImageContainer>
    </Container >
  );
}
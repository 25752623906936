import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Components
import { PhaseButton } from './PhaseButton';

export function BottomPhasesNav({
  onClick,
  currentPhase,
  phases,
  ...props
}) {
  const [width, setWidth] = React.useState(null);

  React.useEffect(() => {
    setWidth(document.getElementsByName("phase-button")[0].clientWidth);
    window.onresize = function () {
      if (document.getElementsByName("phase-button").length !== 0) {
        setWidth(document.getElementsByName("phase-button")[0].clientWidth);
      }
    };
  }, [currentPhase, width]);

  return (
    <div className="bottom-phases d-flex flex-row align-items-end" style={{ padding: 0.5 }}{...props}>
      {phases.map(({ idx, item }, key) => {
        return (
          <div className={classnames("flex-grow-1", { active: key === currentPhase, "cursor-pointer": currentPhase <= idx })} key={key} onClick={currentPhase === idx ? onClick : undefined}>
            <PhaseButton
              step={idx}
              active={true}
              title={item.name}
              height={width / 2.5}
              width={width}
              currentPhase={currentPhase}
            />
          </div>
        );
      })}
    </div>
  );
}

BottomPhasesNav.propTypes = {
  onClick: PropTypes.func.isRequired,
  currentPhase: PropTypes.number.isRequired,
  phases: PropTypes.arrayOf(PropTypes.object).isRequired
}
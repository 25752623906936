import React from "react";
import { motion } from "framer-motion";

import { Typography } from "../common";

const colors = {
  primary: "#25A186",
  activeStep: "#F28044",
  yellow: "#FAEF83",
  greenFinished: "#1ba488",
  green: "#56c1ab",
};

export const PhaseButton = ({
  title,
  step,
  currentPhase,
  height,
  width,
  active,
  key,
  ...props
}) => {
  const [state, setState] = React.useState("deactivated");
  const [resolution, setResolution] = React.useState(null)

  function updateResolution() {
    setResolution(Math.min(1.5625 * window.innerWidth, 2.77 * window.innerHeight) / 45600 * 10.666);
    window.onresize = function () {
      if (document.getElementsByTagName("html").length !== 0) {
        setResolution(Math.min(1.5625 * window.innerWidth, 2.77 * window.innerHeight) / 45600 * 10.666);
      }
    };
  }

  React.useEffect(() => {
    if (currentPhase > step) {
      setState("finished");
    } else if (currentPhase === step) {
      setState("activated");
    } else {
      setState("deactivated");
    }
    updateResolution();

  }, [currentPhase, width, height]);

  const borderWidth = 4.5 * resolution;
  const heightAnim = 30 * resolution;

  const outputRange = [
    `M${72 * resolution} ${borderWidth} L ${borderWidth} ${63 * resolution} V ${height + heightAnim - borderWidth} H
     ${width - borderWidth} V ${63 * resolution} L ${width - 75.5 * resolution} ${borderWidth} H ${72 * resolution} Z`,

    `M${72 * resolution} ${borderWidth} L ${borderWidth} ${63 * resolution} V ${height - borderWidth} H 
    ${width - borderWidth} V ${63 * resolution} L ${width - 75.5 * resolution} ${borderWidth} H ${72 * resolution} Z`,
  ];


  let clip_path_variants = {
    activated: {
      d: outputRange[0],
      fill: colors.activeStep,
      stroke: colors.yellow,
    },
    deactivated: {
      d: outputRange[1],
      fill: colors.green,
      stroke: colors.green,
    },
    finished: {
      d: outputRange[0],
      fill: colors.greenFinished,
      stroke: colors.greenFinished,
    },
  };

  let viewBox = {
    activated: {
      viewBox: `0, 0, ${width}, ${height + heightAnim}`,
      height: height + heightAnim,
      transition: { duration: 0.3 },
    },
    deactivated: {
      viewBox: `0, 0, ${width}, ${height}`,
      height: height,
    },
    finished: {
      viewBox: `0, 0, ${width}, ${height}`,
      height: height,
    },
  };

  let divHeight = {
    activated: {
      height: height + heightAnim,
      transition: { duration: 0.3 },
    },
    deactivated: {
      style: {
        height: height * resolution,
      },
    },
    finished: {
      style: {
        height: height * resolution,
      }
    }
  }

  return (
    <motion.div
      name="phase-button"
      style={{
        flexGrow: 1,
        height: height,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }
      }
      variants={divHeight}
      animate={state}
    >
      <Typography
        variant={"subtitle16"}
        style={{
          width: width / 2,
          textAlign: "center",
          zIndex: 1,
        }}
      >
        {state === "finished" ? `${title} Concluído` : state === "deactivated" ? title : `Concluir ${title}`}
      </Typography>

      {
        !!width ? (
          <motion.svg
            width={width}
            height={state === "activated" ? height + heightAnim : height}
            viewBox={[0, 0, width, height]}
            variants={viewBox}
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            style={{
              position: "absolute",
              bottom: 0,
              zIndex: 0,
            }}
            filter={"url(#shadow_mask)"}
            preserveAspectRatio='xMaxYMax meet'
            animate={state}
            transition={{ duration: 0.2 }}
          >
            <motion.g>
              <motion.path
                fill={colors.activeStep}
                variants={clip_path_variants}
                d={`M`}
                stroke={"#FAEF83"}
                strokeWidth={9 * resolution}
                animate={state}
                transition={{ duration: 0.2 }}
              />
            </motion.g>
            <motion.defs>
              <motion.filter
                id='shadow_mask'
                x='-1'
                y={-heightAnim}
                width={width + borderWidth}
                height={height + 2 * heightAnim}
                filterUnits='userSpaceOnUse'
                color-interpolation-filters='sRGB'
              >
                <feFlood floodOpacity='0' result='BackgroundImageFix' />
                <feColorMatrix
                  in='SourceAlpha'
                  type='matrix'
                  values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                />
                <feOffset dy={-8 * resolution} />
                <feGaussianBlur stdDeviation='5' />
                <feColorMatrix
                  type='matrix'
                  values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0'
                />
                <feBlend
                  mode='normal'
                  in2='BackgroundImageFix'
                  result='effect1_dropShadow'
                />
                <feBlend
                  mode='normal'
                  in='SourceGraphic'
                  in2='effect1_dropShadow'
                  result='shape'
                />
              </motion.filter>
            </motion.defs>
          </motion.svg>
        ) : null
      }
    </motion.div >
  );
};

PhaseButton.defaultProps = {
  state: "deactivated",
};
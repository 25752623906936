import React from "react";

// Components
import { Container, Button, Typography, Modal } from "../components/common";
import { CircleButton } from "../components/CircleButton";
import { CaseCard } from "../components/CaseCard";

// Dialogs
import { AlertScreen } from "./dialog/AlertScreen";
import { ConfigurationScreen } from "./dialog/ConfigurationScreen";

// Icons
import {
  AlertIcon,
  ArrowIcon,
  CreditsIcon,
  SyncIcon,
  ConfigurationIcon,
  LogoutIcon,
} from "../assets/icons";

// Services
import { getPagination } from "../services/utils";
import { api } from "../services/api";
import { LocalStorage, localStorageKeys } from "../services/localStorage";
import { UserContext } from "../UserContext";
import { CreditsScreen } from "./CreditsScreen";
import { HistoryScreen } from "./HistoryScreen";
import { appColors } from "../styles";
import { AuthContext } from "../contexts/AuthContext";

const { gameCode } = require("../config/game.config.json");

export const MenuScreen = ({ history, match, ...props }) => {
  const { signOut } = React.useContext(AuthContext);

  const [caseList, setCaseList] = React.useState();
  const [paginationIndex, setPaginationIndex] = React.useState(0);
  const [modal, setModal] = React.useState({
    visible: false,
    modal: null,
  });

  React.useEffect(() => {
    (async function () {
      api
        .get(
          match.params.id
            ? `/games/categories/${match.params.id}/cases`
            : "/games/cases?head=true"
        )
        .then((response) => {
          // Atualizar data de atualização do último acesso do usuário
          setCaseList(response.data);
        })
        .catch((error) => {
          const { message } = error;
          console.log(error);
        });
    })();

    function keyDownListener(e) {
      e.preventDefault();
      if (e.key == "Escape" || e.key == "Esc") {
        setModal({ visible: false, modal: null });
      }
      return false;
    }

    window.addEventListener("keydown", keyDownListener);

    return () => {
      window.removeEventListener("keydown", keyDownListener);
    };
  }, []);

  const handleCaseSelection = (selectedCase) => () => {
    history.push("/jogar", { caseId: selectedCase.id });
  };

  const handlePagination = () => {
    setPaginationIndex(getPagination(caseList, 3, paginationIndex));
  };

  function handleDisconnect() {
    setModal({
      visible: true,
      modal: (
        <AlertScreen
          message={"Você realmente deseja se desconectar?"}
          onConfirm={() => {
            signOut();
          }}
        />
      ),
    });
  }

  function handleConfiguration() {
    setModal({
      visible: true,
      modal: <ConfigurationScreen allowLanguageChange />,
    });
  }

  const modalComponent = (
    <Modal
      visible={modal.visible}
      onClose={() => setModal({ visible: false, modal: null })}
    >
      {modal.modal}
    </Modal>
  );

  return (
    <Container
      containerClassName="bg-blue"
      className="menu-container"
      overflowChildren={modalComponent}
    >
      <div className="clear-both">
        <div className="gray-box float-left">
          <Typography variant="header24">Escolha seu jogo</Typography>
        </div>
        <Button
          className="float-right"
          onClick={() => {
            setModal({
              visible: true,
              modal: <HistoryScreen />,
            });
          }}
        >
          Histórico
        </Button>
      </div>

      <div className="flex-grow-1 d-flex align-items-center">
        {!caseList ? (
          <div className="loader-container">
            <span className="loader" />
            <Typography style={{ color: appColors.highEmphasisWhiteText }}>
              Carregando casos...
            </Typography>
          </div>
        ) : (
          <>
            <div className="row flex-grow-1 mr-2">
              {caseList &&
                caseList
                  .slice(3 * paginationIndex, 3 * (1 + paginationIndex))
                  .map((item, key) => {
                    return (
                      <div className="col-4" key={key}>
                        <CaseCard
                          title={item.title}
                          onClick={handleCaseSelection(item)}
                          image={`${api.defaults.baseURL}/static/images/cases/${item.id}/paciente-card.png`}
                        />
                      </div>
                    );
                  })}
            </div>

            <div style={{ width: 'calc(3.42462 * var(--sp))' }}>
              {caseList && caseList.length > 3 && (
                <button className="btn-svg ml-2" onClick={handlePagination}>
                  <ArrowIcon
                    fill="white"
                    style={{ height: "calc(4 * var(--sp))" }}
                  />
                </button>
              )}
            </div>
          </>
        )}
      </div>

      <div>
        <div className="pull-right">
          <CircleButton
            className="mr-2"
            onClick={() => {
              setModal({
                visible: true,
                modal: <CreditsScreen />,
              });
            }}
          >
            <CreditsIcon className="h-100 w-100" />
          </CircleButton>

          <CircleButton className="mr-2" onClick={handleConfiguration}>
            <ConfigurationIcon className="h-100 w-100" />
          </CircleButton>

          <CircleButton onClick={handleDisconnect}>
            <LogoutIcon className="h-100 w-100" />
          </CircleButton>
        </div>
      </div>
    </Container>
  );
};

import React from 'react';
import PropTypes from 'prop-types';

import { Typography } from './common';

import { CheckedIcon } from '../assets/icons';

export function CheckBox({
  onOptionClick,
  label,
  checked,
  backgroundColor,
  ...props
}) {
  return (
    <div className="d-flex" {...props}>
      <button className="checkbox" onClick={onOptionClick}>
        {checked && <CheckedIcon className='checkbox-icon' />}
      </button>
      <Typography regular variant="header20">{label}</Typography>
    </div>
  );
}

CheckBox.propTypes = {
  onOptionClick: PropTypes.func.isRequired,
  label: PropTypes.string,
  checked: PropTypes.bool,
  backgroundColor: PropTypes.string
}

CheckBox.defaultProps = {
  backgroundColor: '#FFF',
  checked: false
}
import React from 'react';
import { Redirect } from 'react-router-dom';

// Views
import { IntroductionScreen } from './IntroductionScreen';
import { EndingScreen } from './EndingScreen';
import { AvatarScreen } from './AvatarScreen';
import { GameScreen } from './GameScreen';

// Context
import { Attendance } from '../../services/classes/Attendance';
import { api } from '../../services/api';

export class GameplayScreen extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false,
      hasError: false,
      attendance: new Attendance()
    };

    this.navigate = this.navigate.bind(this);
  }

  componentDidMount() {
    const { location } = this.props;

    if (!location.state) {
      return this.setState({ isLoaded: true, hasError: true });
    }

    api.get('/games/cases/' + location.state.caseId)
      .then(response => {
        if (location.state) {
          this.state.attendance.setGameCase(response.data);
          document.title = response.data.title;
        }
        this.setState({ isLoaded: true });
        this.forceUpdate();
      });
  }

  navigate(route, params) {
    this.props.history.push(route, { attendance: JSON.stringify(this.state.data), ...params });
  }

  reloadCase() {
    const attendance = new Attendance();
    attendance.setGameCase(this.state.attendance.case.data);
    this.setState({
      isLoaded: false,
      hasError: false,
      attendance: attendance
    })
  }

  render() {
    const { isLoaded, hasError, attendance } = this.state;
    const rerender = () => this.forceUpdate();

    if (isLoaded && hasError) {
      return <Redirect to="/" />
    }

    if (!attendance.avatar) {
      return (
        <AvatarScreen rerender={rerender} attendance={attendance} {...this.props} />
      );
    }

    if (!attendance.ready) {
      return (
        <IntroductionScreen rerender={rerender} attendance={attendance} caseId={this.props.location.state.caseId} {...this.props} />
      );
    }

    if (attendance.finalScore === null) {
      return (
        <GameScreen rerender={rerender} attendance={attendance} onNavigate={this.navigate} {...this.props} />
      );
    }

    return (
      <EndingScreen rerender={rerender} attendance={attendance} onNavigate={this.navigate} reloadCase={() => this.reloadCase()} {...this.props} />
    );
  }
}
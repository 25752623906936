export const localStorageKeys = {
  ACCESS_TOKEN: '@access_token',
  SOUND_ENABLED: '@sound_enabled',
  LANGUAGE: '@language'
}

export const LocalStorage = {
  store: (key, value) => {
    try {
      const jsonValue = JSON.stringify(value);
      localStorage.setItem(key, jsonValue);
    } catch (e) {
      // saving error
      console.error(e);
    }
  },
  retrieve: (key, defaultValue) => {
    try {
      const value = localStorage.getItem(key);
      return JSON.parse(value) || defaultValue;
    } catch (e) {
      // error reading value
      console.error(e);
    }
  }
}
import React from "react";
import PropTypes from "prop-types";

import classnames from "classnames";

import "./Typography.css";

function Typography({
  className,
  variant,
  bold,
  italic,
  regular,
  children,
  paragraph,
  wrap,
  ...props
}) {
  const classes = classnames("gc-text", className, variant, {
    "text-bold": bold,
    "text-italic": italic,
    "text-regular": regular,
    "text-paragraph": paragraph,
    "word-warp":wrap
  });

  return (
    <p className={classes} {...props}>
      {children}
    </p>
  );
}

Typography.propTypes = {
  variant: PropTypes.oneOf([
    "button14",
    "header34",
    "header48",
    "header24",
    "header30",
    "header20",
    "subtitle14",
    "subtitle16",
    "caption12",
    "overline10",
  ]),
  bold: PropTypes.bool,
  regular: PropTypes.bool,
  italic: PropTypes.bool,
  paragraph: PropTypes.bool,
  className: PropTypes.string
};

Typography.defaultProps = {
  variant: "subtitle14",
  bold: false,
  regular: false,
  italic: false,
  paragraph: false
};

export default Typography;
import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import { CloseIcon } from "../../assets/icons";

import { Typography, Button } from "../../components/common";

// Services
import { LocalStorage, localStorageKeys } from "../../services/localStorage";

export function ConfigurationScreen({
  allowLanguageChange,
  onClose
}) {
  const [soundEnabled, setSoundEnabled] = React.useState(true);
  const [language, setLanguage] = React.useState(true);

  React.useEffect(() => {
    const _soundEnabled = LocalStorage.retrieve(
      localStorageKeys.SOUND_ENABLED, 0,
    );
    const _language = LocalStorage.retrieve(
      localStorageKeys.LANGUAGE, "pt-br",
    );
    setSoundEnabled(_soundEnabled === 1);
    setLanguage(_language);
  }, []);

  function handleClose() {
    if (onClose) {
      onClose();
    }
  }

  const handleLanguageChange = (language) => () => {
    LocalStorage.store(localStorageKeys.LANGUAGE, language);
    setLanguage(language);
  };

  const handleToggleSound = () => {
    LocalStorage.store(localStorageKeys.SOUND_ENABLED, Number(!soundEnabled));
    setSoundEnabled(!soundEnabled);
  };

  return (
    <div className={"config-outside-container"}>
      <div className="config-container p-relative">
        <button className="p-absolute btn-svg" onClick={handleClose} style={{ top: 'calc(2 * var(--sp))', right: 'calc(2 * var(--sp))' }}>
          <CloseIcon fill='var(--black)' style={{ height: 'calc(1.427 * var(--sp))', width: 'calc(1.427 * var(--sp))' }} />
        </button>

        <Typography
          bold
          variant='header34'
          style={{ textAlign: "center", marginBottom: "calc(1.427 * var(--sp))" }}
        >
          Configurações
        </Typography>

        <div>
          {/* Sons */}
          <div className="row mb-2">
            <div className="col-4">
              <Typography variant='header34'>Sons</Typography>
            </div>
            <div className="col-8 row">
              <div className="col-6">
                <Button
                  className="w-100"
                  disabled={soundEnabled}
                  onClick={handleToggleSound}
                >On</Button>
              </div>
              <div className="col-6">
                <Button
                  className="w-100"
                  disabled={!soundEnabled}
                  onClick={handleToggleSound}
                >Off</Button>
              </div>
            </div>
          </div>

          {/* Língua */}
          <div className="row">
            <div className="col-4">
              <Typography variant='header34'>Língua</Typography>
            </div>
            <div className="col-8 row">
              <div className="col-12 mb-2">
                <Button
                  onClick={handleLanguageChange("pt-br")}
                  disabled={!allowLanguageChange || language === "pt-br"}
                  className="w-100"
                >Português</Button>
              </div>
              <div className="col-12 mb-2">
                <Button
                  onClick={handleLanguageChange("es")}
                  disabled={!allowLanguageChange || language === "es"}
                  className="w-100"
                >Espanhol</Button>
              </div>
              <div className="col-12 mb-2">
                <Button
                  onClick={handleLanguageChange("en-us")}
                  disabled={!allowLanguageChange || language === "en-us"}
                  className="w-100"
                >Inglês</Button>
              </div>
              <div className="col-12 mb-2">
                <Button className="w-100 bg-active">Fale Conosco</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ConfigurationScreen.propTypes = {
  allowLanguageChange: PropTypes.bool,
};

ConfigurationScreen.defaultProps = {
  allowLanguageChange: false,
};

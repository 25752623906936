import { api } from "../api";

const { phases } = require("../../config/game.main.json");
const { interferences } = require("../../config/game.interference.json");

export class GameCase {
  constructor(gameCase) {
    this.data = gameCase;
    this.interference = null;
  }

  getBackroundUrl = (etapaKey) => {
    const imgSlug = etapaKey === 2 ? 'cenario-exame.png' : 'cenario.png';
    return `url(${api.defaults.baseURL}/static/images/cases/${this.data.id}/${imgSlug})`
  }

  getPatientUrl = (etapaKey, pagination) => {
    if (etapaKey === 0) {
      return pagination >= 1 ? `url(${api.defaults.baseURL}/static/images/cases/${this.data.id}/paciente.png)` : null;
    }
    if (etapaKey === 4) {
      return null;
    }
    const imgSlug = etapaKey === 2 ? 'paciente-exame.png' : 'paciente.png';
    return `url(${api.defaults.baseURL}/static/images/cases/${this.data.id}/${imgSlug})`
  }

  getOptions(etapaKey) {
    switch (etapaKey) {
      case 1: return this.data.questions.reduce((prev, { id, feedback, reference, type, ...q }) => {
        prev.push({
          text: q.text,
          feedback_text: q.response,
          medical_record_text: q.medical_record_result,
          type, feedback, reference, id
        });
        if (type === 0 && q.coarse_text) {
          prev.push({
            text: q.coarse_text,
            feedback_text: q.response,
            medical_record_text: q.medical_record_result,
            type: -1,
            feedback, reference, id
          });
        }
        return prev;
      }, []).sort((a, b) => a.order - b.order);
      case 2: return this.data.exams.filter(i => !!i).map(({ id, feedback, reference, type, ...e }) => ({
        text: e.text,
        feedback_text: e.result,
        file: e.file,
        medical_record_text: e.medical_record_result,
        type, feedback, reference, id
      })).sort((a, b) => a.order - b.order);
      case 3:
      case 5: return this.data.diagnosis.filter(i => !!i).map(({ feedback, reference, id, type, ...d }) => ({
        text: d.text,
        type, feedback, reference, id
      }));
      case 4: return this.data.complementary_exams.filter(i => !!i).map(({ id, feedback, reference, type, ...e }) => ({
        text: e.text,
        feedback_text: e.result,
        file: e.file,
        financial_cost: e.financial_cost,
        medical_record_text: e.medical_record_result,
        type, feedback, reference, id
      }));
      case 6: return this.data.conducts.filter(i => !!i).map(({ type, feedback, reference, id, ...c }) => ({
        text: c.text,
        financial_cost: c.financial_cost,
        type, feedback, reference, id
      }));
      case 7: return this.data.communications.filter(i => !!i).map(({ type, id, ...c }) => ({
        text: c.text,
        type, id
      }));
      default: return [];
    }
  }

  hasOptions(etapaKey) {
    let opts;
    switch (etapaKey) {
      case 1: return Boolean(this.data.questions && this.data.questions.length);
      case 2:
        opts = this.data.exams;
        return Boolean(opts && opts.length);
      case 3: return Boolean(this.data.diagnosis && this.data.diagnosis.length);
      case 4:
        opts = this.data.complementary_exams;
        return Boolean(opts && opts.length);
      case 5: return Boolean(this.data.diagnosis && this.data.diagnosis.length);
      case 6: return Boolean(this.data.conducts && this.data.conducts.length);
      case 7: return Boolean(this.data.communications && this.data.communications.length);
      default: return false;
    }
  }

  static getMaxScore(options, phaseKey, extra) {
    if (!options) {
      return 0;
    }
    const pts = options.filter(o => o.type === 0).length * 20000;
    if (extra) {
      return extra + pts;
    }
    return pts;
  }

  static getScore(option, etapa) {
    if (etapa === 5 || etapa === 6) {
      switch (option.type) {
        case 1: return (option.financial_cost === 1 ? -10000 : -20000) + this.scoreFrom(option);
        case 2: return (option.financial_cost === 1 ? -30000 : -50000) + this.scoreFrom(option);
        default: return this.scoreFrom(option);
      }
    } else {
      return this.scoreFrom(option);
    }
  }

  static scoreFrom(option) {
    switch (option.type) {
      case 0: return 20000;
      case 1: return 2500;
      case 2: return -20000;
      default: return 5000;
    }
  }

  static isPlayerSpeech = (step) => step <= 1 || step >= 6;
  static isPatientSpeech = (step) => step <= 1 || step >= 6;

  getRandomInterference() {
    function isValidForCase(interference, gameCase) {
      // Se o paciente não possui acompanhante, impedir interferências com acompanhantes
      if (interference.need_companion && !gameCase.patient_accompanied) {
        return false;
      }
      // Se o caso não possui interferência de paciente, impedir acontecimento
      if (interference.key === "PACIENTE_DESCONTROLE_EMOCIAL" && !gameCase.has_patient_interference) {
        return false;
      }
      // Se o o paciente não for criança, impedir acontecimento
      if (interference.key === "CRIANCA_NAO_PARA_QUIETA" && !gameCase.patient_age < 13) {
        return false;
      }
      if (interference.key === "MAU_ACOMPANHANTE" && !gameCase.patient_age < 13) {
        return false;
      }
      // Nunca iniciar essa interferência
      if (interference.key === "DOIS_ACOMPANHANTES_QUEREM_RESPONDER" || interference.key === "LIGACAO_CHEGANDO") {
        return false;
      }
      return true;
    }

    const isAnamneseInterference = Math.random() < 0.5;
    const inters = interferences
      .filter(interf => isValidForCase(interf, this.data))
      .filter(interf => interf.phase === "BOTH" || (isAnamneseInterference ? interf.phase === "ANAMNESE" : interf.phase === "CLINICAL"))
      .map(interf => ({ ...interf, phase: isAnamneseInterference ? 1 : 2 }));

    const randInt = Math.floor(Math.random() * inters.length);

    const interference = inters[randInt];
    return interference;
  }
}
import React from "react";
import { motion, useMotionValue, animate } from "framer-motion";

import "./Modal.css";

function Modal({
  children,
  onClose,
  visible,
  ...props
}) {
  const fadeAnim = useMotionValue(0);

  const close = () => {
    animate(fadeAnim, 0, {
      duration: 0.2,
      onComplete: () => {
        if (onClose) {
          onClose();
        }
      },
    });
  };

  const show = () => {
    animate(fadeAnim, 1, {
      duration: 0.3,
    });
  };

  if (!visible) {
    return null;
  }
  show();

  return (
    <motion.div style={{ opacity: fadeAnim }} className="modal">
      {children && React.cloneElement(children, { onClose: close })}
    </motion.div>
  );
}

export default Modal;
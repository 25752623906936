import React from "react";
import classnames from "classnames";
import { animate, motion, useMotionValue, useTransform } from "framer-motion";

// Components
import { Typography, Modal, Container } from "../../components/common";
import { PlayerMessageBox } from "../../components/gameplay/PlayerMessageBox";
import { BottomPhasesNav } from "../../components/gameplay/BottomPhasesNav";
import { CircleButton } from "../../components/CircleButton";

import {
  ArrowIcon,
  MedicalRecordIcon,
  ConfigurationIcon,
  HelpIcon,
  HomeIcon,
  CloseIcon,
} from "../../assets/icons";

// Gameplay Components
// import { PhaseButton } from '../../components/gameplay/PhaseButton';
// import { SpeechThink } from '../../components/gameplay/SpeechThink';

// Dialogs
import { AlertScreen } from "../dialog/AlertScreen";
import { ConfigurationScreen } from "../dialog/ConfigurationScreen";

// Services
import { GameCase } from "../../services/classes/GameCase";
import { shuffle, getPagination } from "../../services/utils";
// import { getRandomInterference } from '../../services/interference';

// Misc
import ThinkCircles from "../../assets/images/think-circles.png";
import { api } from "../../services/api";
import { FileScreen } from "../dialog/FileScreen";
import { MedicalRecordScreen } from "./MedicalRecordScreen";
import { InterferenceScreen } from "./InterferenceScreen";

const { phases } = require("../../config/game.main.json");

const PRODUCTION = process.env.NODE_ENV !== "development";
const debugLog = (message) => {
  if (!PRODUCTION) console.log(message);
};

export const GameScreen = ({ rerender, attendance, onNavigate, ...props }) => {
  const [clicksCount, setClicksCount] = React.useState(0);

  // phaseKey é a fase atual do atendimento, a phase é obtida com phases[phaseKey];
  const [phaseKey, setPhaseKey] = React.useState(null);
  const [timestamp, setTimestamp] = React.useState(null);

  // Controla as opções e a quantidade das mesmas exibidas ao jogador
  const [options, setOptions] = React.useState({
    options: [],
    pagination: 0,
    perPage: 2,
  });

  // Controla a paginação das falas
  const [speechControl, setSpeechControl] = React.useState({
    pagination: 0,
    enableOptions: false,
  });

  // Controla o Feedback do paciente / exames
  const [feedbackContent, setFeedbackContent] = React.useState({
    isSpeech: true,
    text: "",
    visible: false,
  });

  // Controla os modais de configuração e demais diálogos
  const [modal, setModal] = React.useState({ visible: false, modal: null });

  // Controla a animação da barra de pontuação
  const scoreAnim = useMotionValue(0);

  // useEffect hooks
  React.useEffect(() => {
    const etapaInicialIndex = 0;
    setPhaseKey(PRODUCTION ? 0 : etapaInicialIndex);

    // Gera um número aleatório no intervalo inteiro [0,5]
    const rndNumber = (Math.random() * 6) << 0;

    // Como a chance de rndNumber se igual a 2 é de 1/6 (16.6%), essa verificação
    // tem mesmo efeito de "acontecer uma inteferência a cada 6 jogadas"
    if (rndNumber === 2 || !PRODUCTION) {
      try {
        attendance.buildInterference();
      } catch (e) {
        console.error(e);
      }
    }
  }, []);

  React.useEffect(() => {
    function keyDownListener(e) {
      e.preventDefault();
      if (e.key == "Escape" || e.key == "Esc") {
        setModal({ visible: false, modal: null });
      }
      return false;
    }

    window.addEventListener("keydown", keyDownListener);

    return () => {
      window.removeEventListener("keydown", keyDownListener);
    };
  }, []);

  React.useEffect(() => {
    // A cada mudança de fase, atualizar pontuação máxima e opções a serem exibinas na tela
    if (phaseKey) {
      debugLog(`>> CONFIGURING PHASE ${phaseKey} ("${phases[phaseKey].name}")`);
      const options = attendance.getOptions(phaseKey);
      setTimestamp(Date.now());

      debugLog([`>> OPTIONS FOUNDED`, options]);
      if ((options && options.length > 0) || phaseKey === 0) {
        // Caso existam opções, as exiba. Caso a fase seja 0, ainda que sem opções, a comece.
        setOptions({
          options:
            phaseKey === 1 || phaseKey === 2 ? options : shuffle(options), // Use 'shuffle(options)' para ordenar aleatóriamente as opções
          pagination: 0,
          perPage: phaseKey === 3 || phaseKey === 5 ? 3 : 2,
        });
      } else {
        debugLog(`ENDING PHASE: No options founded for phase ${phaseKey}`);
        handleEndPhase();
      }
    }
  }, [phaseKey]);

  React.useEffect(() => {
    setFeedbackContent({ visible: false });
  }, [phaseKey, options.pagination]);

  const animateScore = (score) => {
    animate(scoreAnim, score || 0, {
      stiffness: 2000,
      duration: 0.3,
    });
  };

  // Handler functions
  function handleRecordClick() {
    setModal({
      visible: true,
      modal: <MedicalRecordScreen attendance={attendance} />,
    });
  }

  function handleMenu() {
    setModal({
      visible: true,
      modal: (
        <AlertScreen
          message="Voltar para o menu?"
          onConfirm={() => onNavigate("/categorias")}
        />
      ),
    });
  }

  const handleOptionClick = (defaultOptionIndex) => (optionIndex) => {
    const opIndex = defaultOptionIndex + optionIndex;
    const option = options.options[opIndex];

    if (!option.checked) {
      attendance.addOption(option, phaseKey, opIndex);

      // Não anime a barra de pontuação nessas etapas
      if (phaseKey !== 3 && phaseKey !== 5 && phaseKey !== 7) {
        animateScore(attendance.getScore(phaseKey));
      }

      if (attendance.interference) {
        const interfStart = tryStartInterference();
        debugLog("Start inteference?", String(Boolean(interfStart)));
        if (interfStart) {
          if (attendance.interference.forced_response) {
            if (attendance.interference.responses_until_start > 0) {
              // Muda execução mas não renderiza componente
              attendance.interferenceState = 1;
              attendance.interference.responses_until_start -= 1;
              if (option.feedback) {
                const text =
                  attendance.interference.key === "RUIDOS_EXTERNOS"
                    ? shuffle(option.feedback.split("")).join("")
                    : attendance.interference.forced_response;
                setFeedbackContent({
                  isSpeech: GameCase.isPatientSpeech(phaseKey),
                  text,
                  visible: true,
                });
              }
            } else {
              tryStartInterference(true);
            }
          }
          return;
        }
      }

      if (option.file) {
        setModal({
          visible: true,
          modal: <FileScreen file={option.file} />,
        });
      }
      if (option.feedback_text) {
        setFeedbackContent({
          isSpeech: GameCase.isPatientSpeech(phaseKey),
          text: option.feedback_text,
          visible: true,
        });
      }

      setClicksCount(1 + clicksCount);
    }
  };

  function handleConfiguration() {
    setModal({
      visible: true,
      modal: <ConfigurationScreen allowLanguageChange={false} />,
    });
  }

  function handleEndPhase() {
    if (attendance.interference) {
      if (
        attendance.interference.phase === phaseKey &&
        attendance.interference.state !== 2
      ) {
        // Força o início de uma interferência
        tryStartInterference(true);
        return;
      }
    }

    // Restart ScoreBar value
    scoreAnim.set(0);

    // Save timestamp
    if (attendance.data[phaseKey]) {
      attendance.data[phaseKey].duration = Date.now() - timestamp;
    }
    // Caso o usuário tenha selecionado um diagnóstico final não adequado, o caso se encerra.
    if (
      phaseKey === 7 ||
      (phaseKey === 5 && attendance.selections[5]?.type !== 0)
    ) {
      handleEndGame();
      return;
    }

    // Prossegue para a próxima etapa
    setPhaseKey(phaseKey + 1);

    setSpeechControl({
      pagination: 0,
      enableOptions: false,
    });

    // Caso deva, abre o prontuário médico
    if (phaseKey !== 0 && phaseKey !== 3 && phaseKey !== 7) {
      setModal({
        visible: true,
        modal: (
          <MedicalRecordScreen
            attendance={attendance}
            page={phaseKey > 3 ? phaseKey - 1 : phaseKey}
          />
        ),
      });
    }
  }

  function handleChangePagination() {
    debugLog("Pagination clicked");
    // Anamnese
    if (phaseKey === 0) {
      const maxPage = phases[phaseKey].messages.length - 1;

      // Verifica se chegou ao final das falas
      if (speechControl.pagination < maxPage) {
        // Caso negativo, prossegue com as falas
        setSpeechControl((old) => ({
          ...old,
          pagination: 1 + speechControl.pagination,
        }));

        if (speechControl.pagination === 0) {
          setFeedbackContent({
            text:
              attendance.case.data.patient_greeting ??
              `Olá! Meu nome é ${attendance.case.data.patient_name}`,
            visible: true,
            isSpeech: true,
          });
        } else if (speechControl.pagination === 1) {
          setFeedbackContent({
            text: attendance.case.data.patient_complaiment,
            visible: true,
            isSpeech: true,
          });
        }
      } else {
        debugLog("ENDING PHASE: No more messages to display.");
        handleEndPhase();
      }
      return;
    }

    if (phaseKey === 3 && attendance.selections[3]) {
      debugLog("ENDING PHASE: The player has chosed a diagnosis");
      handleEndPhase();
    }

    // Verifica se as opções da etapa devem ser exibidas
    if (speechControl.enableOptions) {
      // Caso positivo, verifica o valor máximo da paginação e prossegue com a mesma
      setOptions((old) => ({
        ...old,
        pagination: getPagination(old.options, old.perPage, old.pagination),
      }));
    } else {
      // Caso negativo, verifica o valor máximo da paginação das falas
      const maxPage = phases[phaseKey].messages.length - 1;

      // Verifica se chegou ao final das falas
      if (speechControl.pagination < maxPage) {
        // Caso negativo, prossegue com as falas
        setSpeechControl((old) => ({
          ...old,
          pagination: ++old.pagination,
        }));
      } else {
        // Caso positivo, verifica se a etapa possui opções para serem exibidas
        if (attendance.case.hasOptions(phaseKey) || phaseKey === 7) {
          // Caso positivo, habilita a exibição das opções
          setSpeechControl({
            pagination: 0,
            enableOptions: true,
          });
        } else {
          // Caso negativo, vai para próxima etapa
          debugLog("ENDING PHASE: No options founded.");
          handleEndPhase();
        }
      }
    }
  }

  const score = useTransform(
    scoreAnim,
    [0, attendance.getMaxScore(phaseKey)],
    ["0%", "100%"]
  );

  const handleEndGame = () => {
    attendance.finalScore = Object.keys(attendance.data)
      .filter((k) => k !== 3)
      .map((k) => attendance.data[k])
      .reduce((prev, curr) => prev + curr.score, 0);
    rerender();
  };

  function tryStartInterference(force) {
    // Caso estado seja 2, a interferência não deve ocorrer
    if (attendance.interference.state === 2) {
      return false;
    }
    if (attendance.interference) {
      if (attendance.interference.phase === phaseKey || force) {
        const randomChance = Math.random() <= 0.3;
        const shouldOccure =
          attendance.interference.state === 1 || force || randomChance;
        if (shouldOccure) {
          if (attendance.interference.responses_until_start === 0 || force) {
            attendance.interference.state = 99;
            setClicksCount(clicksCount + 1);
            return false;
          } else {
            // A interferência vai ocorrer, mas depende de um determinado número de respostas do paciente
            return true;
          }
        }
      }
    }
    return false;
  }

  function onInterferenceOptionSelect({ option, extraAction }) {
    debugLog("Interference option called");
    debugLog({ option, extraAction });

    // debugLog(phaseKey);
    // debugLog(attendance.data[phaseKey].score, attendance.data[phaseKey].maxScore);
    // Atualiza a pontuação da etapa
    attendance.data[phaseKey] = {
      score:
        attendance.data[phaseKey].score + GameCase.getScore(option, phaseKey),
      options: attendance.data[phaseKey].options,
      maxScore: attendance.data[phaseKey].maxScore + 20000,
    };
    // debugLog(attendance.data[phaseKey].score, attendance.data[phaseKey].maxScore);

    // Atualiza o estado da interferência
    attendance.interference.state = 2;

    if (!option) {
      execAction(extraAction);
      return;
    }
    execAction(option.action);
    execAction(extraAction);

    // Anima a barra de score
    animateScore(attendance.data[phaseKey].score);

    setClicksCount(1 + clicksCount);
  }

  function execAction(action) {
    switch (action) {
      case "END_GAME":
        debugLog("Encerrando caso!");
        handleEndGame();
        break;
    }
  }

  // Previnir erros de renderização
  if (phaseKey === null) {
    return null;
  }

  const modalComponent = (
    <Modal
      visible={modal.visible}
      onClose={() => setModal({ visible: false, modal: null })}
    >
      {modal.modal}
    </Modal>
  );
  const isSpeech = speechControl.enableOptions
    ? phases[phaseKey].areOptionsSpeech
    : phases[phaseKey].messages[speechControl.pagination].isSpeech;

  if (attendance.interference && attendance.interference.state === 99) {
    return (
      <InterferenceScreen
        attendance={attendance}
        onSelect={onInterferenceOptionSelect}
        phaseKey={phaseKey}
      />
    );
  }

  return (
    <Container
      containerStyle={{ backgroundColor: "black" }}
      overflowChildren={modalComponent}
    >
      <div
        className="bg-image fullscreen"
        style={{ backgroundImage: attendance.case.getBackroundUrl(phaseKey) }}
      >
        <div
          className="bg-image fullscreen"
          style={{
            backgroundImage: attendance.case.getPatientUrl(
              phaseKey,
              speechControl.pagination
            ),
          }}
        >
          {/* Ícone de prontuário (direita) */}
          <div
            className="p-absolute text-align-center"
            style={{
              top: "calc(1.1415 * var(--sp))",
              left: "calc(1.1415 * var(--sp))",
            }}
          >
            <CircleButton
              size={"calc(3.42462 * var(--sp))"}
              className="base-shadow p-2"
              onClick={handleRecordClick}
            >
              <MedicalRecordIcon className="h-100 w-100" />
            </CircleButton>
            <Typography className="text-white" variant="header20">
              Prontuário
            </Typography>
          </div>

          {/* Ícones brancos da esquerda */}
          <div
            className="p-absolute"
            style={{
              top: "calc(1.1415 * var(--sp))",
              right: "calc(4 * var(--sp))",
            }}
          >
            <CircleButton className="base-shadow mr-2" onClick={handleMenu}>
              <HomeIcon className="h-100 w-100" />
            </CircleButton>
            <CircleButton
              className="base-shadow"
              onClick={handleConfiguration}
            >
              <ConfigurationIcon className="h-100 w-100" />
            </CircleButton>
          </div>

          {/* Barra de pontuação */}
          <div className="score-bar base-shadow" style={{
            top: "calc(1.1415 * var(--sp))",
            right: "calc(1.1415 * var(--sp))",
          }}>
            <motion.div className="w-100 bg-orange" style={{ height: score }} />
          </div>

          {/* Caixa de feedback do paciente e resultado de exames */}
          {feedbackContent.visible && (
            <div
              className={classnames(
                "feedback-box base-shadow",
                feedbackContent.isSpeech ? "bg-light-green" : "bg-gray"
              )}
            >
              <Typography regular variant="header20">
                {feedbackContent.text}
              </Typography>
            </div>
          )}

          <div className="player-box">
            <div>
              {/* Caixa de fala e pensamento do jogador */}
              <PlayerMessageBox
                arrowBlink={
                  (speechControl.pagination === 0 &&
                    !speechControl.enableOptions) ||
                  (phaseKey === 3 && attendance.selections[3])
                }
                isSpeech={isSpeech}
                onArrowClick={handleChangePagination}
                onOptionClick={handleOptionClick(
                  options.perPage * options.pagination
                )}
                options={options.options?.slice(
                  options.perPage * options.pagination,
                  options.perPage * (1 + options.pagination)
                )}
                showArrow={
                  options.options.length > options.perPage ||
                  !speechControl.enableOptions ||
                  (phaseKey === 3 && attendance.selections[3])
                }
                showText={!speechControl.enableOptions}
                text={
                  phases[phaseKey].messages[speechControl.pagination].messageKey
                }
              />

              {/* Imagem do médico */}
              <div className="p-absolute avatar base-shadow">
                <img src={attendance.avatar.image} className="avatar" />
                {!isSpeech && (
                  <img src={ThinkCircles} className="think-circles" />
                )}
              </div>
            </div>
          </div>

          {/* BottomPhases */}
          <BottomPhasesNav
            onClick={handleEndPhase}
            currentPhase={phaseKey}
            phases={phases
              .map((curr, idx) => ({ idx, item: curr }))
              .filter((p) => p.item.showInNavigation)}
          />
          {/* End images container */}
        </div>
      </div>
    </Container>
  );
};

import React from "react";

// Components
import { Container, Button, Typography, Modal } from "../components/common";
import { CircleButton } from "../components/CircleButton";
import { CaseCard } from "../components/CaseCard";

// Dialogs
import { AlertScreen } from "./dialog/AlertScreen";
import { ConfigurationScreen } from "./dialog/ConfigurationScreen";

// Icons
import {
  AlertIcon,
  ArrowIcon,
  CreditsIcon,
  SyncIcon,
  ConfigurationIcon,
  LogoutIcon,
} from "../assets/icons";

// Services
import { getPagination } from "../services/utils";
import { api } from "../services/api";
import { LocalStorage, localStorageKeys } from "../services/localStorage";
import { UserContext } from "../UserContext";
import { CreditsScreen } from "./CreditsScreen";
import { HistoryScreen } from "./HistoryScreen";
import { Redirect } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";

const { gameCode } = require("../config/game.config.json");

export const CategoryMenuScreen = ({ history, ...props }) => {
  const { signOut } = React.useContext(AuthContext);
  const { setUser } = React.useContext(UserContext);

  const [categoryList, setCategoryList] = React.useState();
  const [paginationIndex, setPaginationIndex] = React.useState(0);
  const [modal, setModal] = React.useState({
    visible: false,
    modal: null,
  });

  React.useEffect(() => {
    async function loadCasesAsync() {
      api
        .get(`/games/categories`)
        .then((response) => {
          if (response.data.length === 0) {
            return history.push("/menu");
          }
          // Atualizar data de atualização do último acesso do usuário
          setCategoryList(response.data);
        })
        .catch((error) => {
          const { message } = error;
          console.log(message);
        });
    }

    if (gameCode === 1) {
      history.push("/menu");
    } else {
      loadCasesAsync();
    }

    function keyDownListener(e) {
      if (e.key == "Escape" || e.key == "Esc") {
        e.preventDefault();
        setModal({ visible: false, modal: null });
      }
      return false;
    }

    window.addEventListener("keydown", keyDownListener);

    return () => {
      window.removeEventListener("keydown", keyDownListener);
    };
  }, []);

  const handleCategorySelection = ({ id }) => {
    history.push(`/categorias/${id}/casos`);
  };

  const handlePagination = () => {
    setPaginationIndex(getPagination(categoryList, 3, paginationIndex));
  };

  function handleDisconnect() {
    setModal({
      visible: true,
      modal: (
        <AlertScreen
          message={"Você realmente deseja se desconectar?"}
          onConfirm={() => {
            signOut();
          }}
        />
      ),
    });
  }

  function handleConfiguration() {
    setModal({
      visible: true,
      modal: <ConfigurationScreen allowLanguageChange />,
    });
  }

  const modalComponent = (
    <Modal
      visible={modal.visible}
      onClose={() => setModal({ visible: false, modal: null })}
    >
      {modal.modal}
    </Modal>
  );

  if (categoryList && categoryList.length === 0) {
    return <Redirect to="/menu" />;
  }

  return (
    <Container
      containerClassName="bg-blue"
      className="menu-container"
      overflowChildren={modalComponent}
    >
      <div className="clear-both">
        <div className="gray-box float-left">
          <Typography variant="header24">Escolha seu jogo</Typography>
        </div>
        <Button
          className="float-right"
          onClick={() => {
            setModal({
              visible: true,
              modal: <HistoryScreen />,
            });
          }}
        >
          Histórico
        </Button>
      </div>

      <div className="flex-grow-1 d-flex align-items-center">
        <div className="row flex-grow-1 mr-2">
          {categoryList &&
            categoryList
              .slice(3 * paginationIndex, 3 * (1 + paginationIndex))
              .map((item, key) => {
                return (
                  <div className="col-4" key={key}>
                    <CaseCard
                      title={item.title}
                      onClick={() => handleCategorySelection(item)}
                      image={`${api.defaults.baseURL}/static/images/categories/${item.id}/icon.png`}
                    />
                  </div>
                );
              })}
        </div>

        <div style={{ width: 96 }}>
          {categoryList && categoryList.length > 3 && (
            <button className="btn-svg ml-2" onClick={handlePagination}>
              <ArrowIcon fill="white" height={110} />
            </button>
          )}
        </div>
      </div>

      <div>
        <div className="pull-right">
          <CircleButton
            className="mr-2"
            onClick={() => {
              setModal({
                visible: true,
                modal: <CreditsScreen />,
              });
            }}
          >
            <CreditsIcon className="h-100 w-100" />
          </CircleButton>

          <CircleButton className="mr-2" onClick={handleConfiguration}>
            <ConfigurationIcon className="h-100 w-100" />
          </CircleButton>

          <CircleButton onClick={handleDisconnect}>
            <LogoutIcon className="h-100 w-100" />
          </CircleButton>
        </div>
      </div>
    </Container>
  );
};

import React from 'react';
import PropTypes from 'prop-types';
import classname from 'classnames';
import { motion, useMotionValue, animate } from 'framer-motion';

import { Typography } from '../common';
import { ArrowIcon } from '../../assets/icons';
import { CheckBox } from '../CheckBox';

export function PlayerMessageBox({
  isSpeech, showText, text,
  arrowBlink, onArrowClick,
  showArrow, options,
  onOptionClick,
  ...props
}) {
  const fadeAnim = useMotionValue(1);
  const [blinkAnim, setBlinkAnim] = React.useState();

  React.useEffect(() => {
    if (arrowBlink && !blinkAnim) {
      const anim = animate(fadeAnim, 0.2, {
        duration: 0.6,
        onComplete: () => {
          animate(fadeAnim, 1, {
            duration: 0.6,
          });
        },
        yoyo: Infinity,
      });
      setBlinkAnim(anim);
    }
  }, [arrowBlink]);

  const endBlink = () => {
    if (blinkAnim) {
      blinkAnim.stop();
      fadeAnim.set(1);
      setBlinkAnim();
    }
  }

  const handleArrowClick = () => {
    endBlink();
    onArrowClick();
  }

  const handleOptionClick = (i) => () => {
    onOptionClick(i);
  }

  return (
    <div className={classname("player-message-box base-shadow d-flex align-items-center", isSpeech ? "speech" : "no-speech")} {...props}>
      {showText
        ? (
          <div className="flex-grow-1" style={{ flexShrink: 1, justifyContent: 'center' }}>
            <Typography variant="header34">{text}</Typography>
          </div>
        ) : (
          <div className="flex-grow-1 d-flex flex-column h-100" style={{ flexShrink: 1, justifyContent: 'space-between' }}>
            {options.map((option, i) => (
              <CheckBox
                backgroundColor={isSpeech ? '#FFF' : '#ACDCCE'}
                label={option.text}
                onOptionClick={handleOptionClick(i)}
                checked={option.checked}
                style={{ flexGrow: 1 }}
                key={i}
              />
            ))}
          </div>
        )
      }
      {/* <div style={{ flexShrink: 1, justifyContent: 'center' }}>
                  <Typography variant="header34">Olá! Como você se chama?</Typography>
                </div> */}

      {showArrow && (
        // <motion.div style={{ opacity: fadeAnim }}>
        //   <TouchableOpacity activeOpacity={0.9} onOptionClick={handleArrowClick}>
        <motion.div style={{ opacity: fadeAnim }} className="h-75">  
          <button className="btn-svg ml-2 h-100" onClick={handleArrowClick}>
            <ArrowIcon fill='#1BA488' className="h-100" />
          </button>
        </motion.div>
        //   </TouchableOpacity>
        // </motion.div>
      )}
    </div>
  );
}

// const styles = StyleSheet.create({
//   playerSpeechThinkBox: {
//     position: 'absolute',
//     right: 0,
//     left: 104,
//     height: 96,
//     paddingRight: 24,
//     paddingVertical: 8,
//     borderRadius: 40,
//     elevation: 3,
//     // shadowOffset: {
//     //   width: 0,
//     //   height: 2
//     // },
//     // shadowOpacity: 0.8
//   },
//   dropShadow: {
//     shadowOffset: { width: 0, height: 0 },
//     shadowOpacity: 0.5,
//     shadowRadius: 10,
//   },
//   spacedRow: {
//     flexDirection: 'row',
//     justifyContent: 'space-between'
//   }
// });
export function getPagination(items, perPage, oldPage) {
  let newPagination = oldPage + 1;
  if (newPagination > Math.ceil(items.length / perPage) - 1) {
    return 0;
  }
  return newPagination;
}

export function shuffle(array) {
  if (!array) {
    return null;
  }
  let currentIndex = array.length, temporaryValue, randomIndex;
  let rep = 0;

  while (0 !== currentIndex) {
    if (rep > 50) {
      break;
    }
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
    ++rep;
  }

  return array;
}

// function scoreFrom(option) {
//   switch (option.tipo) {
//     case "-": return -20000;
//     case "0": return 2500;
//     case "+": return 20000;
//     default: return 5000;
//   }
// }

// export function getScore(option, etapa) {
//   if (etapa === 'anamnese') {
//     return scoreFrom(option);
//     // if (classificacao != 0) {
//     //   atendimento.PontuacaoAnamnese += points;
//     // } else {
//     //   long pAux = 5000 + (classificacaoAlternativa == 0 ? 15000 : 0);
//     //   atendimento.PontuacaoAnamnese += pAux;
//     //   atendimento.PontuacaoComunicacao += pAux;
//     //   Debug.Log("adding " + pAux + " to anamnsese.");
//     // }
//   } else if (etapa === 'exame_complementar' || etapa === 'tratamento') {
//     switch (option.tipo) {
//       case "0": return (option.custo_fincanceiro === 1 ? -10000 : -20000) + scoreFrom(option);
//       case "-": return (option.custo_fincanceiro === 1 ? -30000 : -50000) + scoreFrom(option);
//       default: return scoreFrom(option);
//     }
//   } else {
//     return scoreFrom(option);
//   }
// }

// export function getMaxScore(options, etapa, extra) {
//   if (!options) {
//     return 0;
//   }
//   return extra + options.map(o => getScore(o, etapa)).filter(s => s > 0).reduce((prev, curr) => prev + curr, 0);
// }

// export function getOptions(gameCase, etapaIndex) {
//   switch (etapaIndex) {
//     case 1: return gameCase.questions;
//     case 2: return gameCase.exams.filter(e => !e.is_complementary);
//     case 3: return gameCase.diagnosis;
//     case 4: return gameCase.exams.filter(e => e.is_complementary);
//     case 5: return gameCase.diagnosis;
//     case 6: return gameCase.conducts;
//     case 7: return gameCase.communications;
//     default: return null;
//   }
//   //map(etapa.index === 7 ? case.comunicacao_tratamento.comunicacoes : case[etapa.db_key], etapa.key)?.filter(i => i);
// }

export function hash(string) {
  var hash = 0, i, chr;
  for (i = 0; i < string.length; i++) {
    chr = string.charCodeAt(i);
    hash = ((hash << 5) - hash) + chr;
    hash |= 0;
  }
  return hash;
}

export function capitalizeTheFirstLetterOfEachWord(words) {
  let separateWord = words.toLowerCase().split(' ');
  for (let i = 0; i < separateWord.length; i++) {
    separateWord[i] = separateWord[i].charAt(0).toUpperCase() +
      separateWord[i].substring(1);
  }
  return separateWord.join(' ');
}